import React, { useState, useEffect, useContext } from "react"
import { GlobalContextProvider } from "../components/functions/GlobalContext"
import {
    Box,
    Text,
    VStack,
    Stack,
    Center,
    Button,
    Grid, SimpleGrid,
} from "@chakra-ui/react"
import InfoModal from "../components/modals/InfoModal"
import Const from "../Consts"
import CountryList from "../components/CountryList"
import StateList from "../components/StateList"
import CityList from "../components/CityList"
import DistrictList from "../components/DistrictList"
import RantingList from "../components/RantingList"
import Pesilat, { PesilatFields } from "../components/Pesilat"
import ImageUpload from "../components/ImageUpload"
import ImageUpload2 from "../components/ImageUpload2"
import CaptchaBox from "../components/Captcha"
import moment from "moment"
import axios from 'axios'
import { AxiosError } from "axios"
import ProgressModal from "../components/modals/progressModal"
import RegisterPrestasiPage, { PrestasiType } from "../components/RegisterPrestasi"
import RantingUpdatePage, { RantingExp, WrapperRTP, RantingToPesilatCreationParam } from "../components/RantingUpdate"
import RegisterCoachExpPage, { CoachExpType } from "../components/RegisterPrestasiCoach"
import { useTranslation } from 'react-i18next';
import { sanitize } from "../components/functions/Common"
import { useNavigate } from 'react-router-dom';
import { NavLink } from "react-router-dom"


export default function RegisterPage() {
    const { t, i18n } = useTranslation();
    const globalContext = useContext(GlobalContextProvider)
    const navigate = useNavigate()

    const [selectedCountry, setSelectedCountry] = useState<number>(0)
    const [pesilatForm, setPesilatForm] = useState<PesilatFields>({
        pesilatId: 0,
        countryId: 0,
        fullname: "",
        email: "",
        username: "",
        password: "",
        password2: "",
        birthdate: moment("01-01-1990", "DD-MM-YYYY").toDate(),
        pendidikan: 0,
        sabuk: 0,
        joindate: "1990",
        sex: "m",
        phone: "",
        isAthlete: false,
        isCoach: false,
        isReferee: false,
        isOther: false,
        otherProfession: "",
        coachLevel: 0,
        refereeLevel: 0,
        refFirstUpgrade: "1990",
        refLastUpgrade: "1990",
        file1Hash: "",
        file2Hash: "",
    })

    const [fileUploaded1, setFileUploaded1] = useState<File | null>(null)
    const [prestasiList, setPrestasiList] = useState<PrestasiType[]>([])
    const [coachExpList, setCoachExpList] = useState<CoachExpType[]>([])
    const [rantingExpList, setRantingExp] = useState<RantingExp[]>([])
    const [fileUploaded2, setFileUploaded2] = useState<File | null>(null)
    const [captchaID, setCaptchaID] = useState<string>("")
    const [captchaAnswer, setCaptchaAnswer] = useState<string>("")
    const [enableSubmit, setEnableSubmit] = useState<boolean>(false)
    const [toRefresh, setToRefresh] = useState<number>(0)
    const [registrationSuccessful, setRegistrationSuccessful] = useState<boolean>(false)
    const [redirectToLogin, setRedirectToLogin] = useState<boolean>(false)


    const [modalData, setModalData] = useState({
        title: "",
        main: "",
        isOpen: false,
    })
    const closeModal = () => {
        setModalData({ ...modalData, isOpen: false })
        if (registrationSuccessful) {
            setRedirectToLogin(true)
        }
    }

    const [progressModal, setProgressModal] = useState({
        title: "Processing Registration",
        progress: 0,
        isOpen: false,
    })
    const closeProgressModal = () => {
        setProgressModal({ ...progressModal, isOpen: false, progress: 0 })
    }



    const countryIsSelected = (id: number) => {
        setSelectedCountry(id)
    }
    const pesilatIsFilled = (enteredData: PesilatFields) => {
        setPesilatForm(enteredData)
    }
    const file1IsUploaded = (id: File | null) => {
        setFileUploaded1(id)
    }
    const file2IsUploaded = (id: File | null) => {
        setFileUploaded2(id)
    }
    const captchaAnswered = (id: string, answer: string) => {
        setCaptchaID(id)
        setCaptchaAnswer(answer)

        if (answer.length == 6) {
            setEnableSubmit(true)
        } else {
            setEnableSubmit(false)
        }
    }
    const prestasiIsAdded = (prestasi: PrestasiType[]) => {
        setPrestasiList(prestasi)
    }
    const coachExpIsAdded = (list: CoachExpType[]) => {
        setCoachExpList(list)
    }
    const rantingExpIsAdded = (list: RantingExp[]) => {
        setRantingExp(list)
    }


    // const updateProgressFunc = (event: any) => {
    //     setProgressModal({ ...progressModal, progress: Math.round((100 * event.loaded) / event.total) })
    // }

    const registerAccount = async () => {
        try {
            let formData = new FormData();
            formData.append("country_id", "" + selectedCountry)
            formData.append("fullname", pesilatForm.fullname)
            formData.append("username", pesilatForm.username)
            formData.append("email", pesilatForm.email)
            formData.append("password", pesilatForm.password)
            formData.append("birthdate", "" + moment(pesilatForm.birthdate).format("x"))
            formData.append("pendidikan", "" + pesilatForm.pendidikan)
            formData.append("sabuk", "" + pesilatForm.sabuk)
            formData.append("joindate", "" + pesilatForm.joindate)
            formData.append("sex", pesilatForm.sex)
            formData.append("phone", pesilatForm.phone)
            formData.append("captcha", captchaID)
            formData.append("answer", captchaAnswer)
            formData.append("file1name", fileUploaded1?.name || "")

            //athlete
            if (pesilatForm.isAthlete) {
                formData.append("is_athlete", "1")
                let serialized = ""
                prestasiList.forEach((value, index) => {
                    serialized += JSON.stringify(value) //Do not add separator in last element
                    if (index < prestasiList.length - 1) {
                        serialized += "|"
                    }
                });
                formData.append("list_prestasi", serialized)
            }

            //coach
            if (pesilatForm.isCoach) {
                formData.append("is_coach", "1")
                formData.append("coach_level", "" + pesilatForm.coachLevel)
                let serialized = ""
                coachExpList.forEach((value, index) => {
                    serialized += JSON.stringify(value) //Do not add separator in last element
                    if (index < coachExpList.length - 1) {
                        serialized += "|"
                    }
                });
                formData.append("coach_exp", serialized)
            }

            if (pesilatForm.isOther) {
                formData.append("other_profession", pesilatForm.otherProfession)
            }

            //referee
            if (pesilatForm.isReferee) {
                formData.append("is_referee", "1")
                formData.append("referee_level", "" + pesilatForm.refereeLevel)
                formData.append("referee_first_upgrade", "" + pesilatForm.refFirstUpgrade)
                formData.append("referee_last_upgrade", "" + pesilatForm.refLastUpgrade)
            }

            //list rantings:
            if (rantingExpList.length > 0) {
                let wrapper: WrapperRTP = { list: [] }
                rantingExpList.forEach((value, _) => {
                    wrapper.list.push({
                        pesilat_id: 0,  //in registration there is no pesilat ID yet
                        ranting_id: value.ranting,
                        active: value.active,
                        position: value.position,
                        year: +value.year,
                    })
                });
                const serialized = JSON.stringify(wrapper)
                formData.append("list_rantings", serialized)
            }

            //for debugging purpose, file upload is put on last
            formData.append("file1", fileUploaded1 || "")
            const fu2 = fileUploaded2?.name || ""
            if (pesilatForm.isReferee && fu2 !== "") {
                formData.append("file2name", fileUploaded2?.name || "")
                formData.append("file2", fileUploaded2 || "")
            }

            //payload is ready========================================================================
            // const res = await axios.post("http://10.2.3.4/", formData,
            const res = await axios.post(Const.API_Register, formData,
                {
                    timeout: 15000,
                    headers: {},
                    // can't find it useful, when there is progress, the modal closed suddenly
                    // onUploadProgress: updateProgressFunc
                }
            )

            if (res.hasOwnProperty("data") && res.data) {
                closeProgressModal()
                setRegistrationSuccessful(true)
                setModalData({
                    ...modalData, isOpen: true, title: t("Registration Successful"),
                    main: t("You have been registered")
                })

            } else {
                console.log(res)
                throw new Error(t("unknown error"))
            }

        } catch (err) {
            let errorToPrint = (err as Error).message
            if (axios.isAxiosError(err)) {
                const error = err as AxiosError
                if (error.response?.hasOwnProperty("data")) {
                    errorToPrint = error.response.data as string
                }
            }
            setModalData({ ...modalData, isOpen: true, title: t("Registration Failed"), main: errorToPrint })
        }

        closeProgressModal()
        setToRefresh(toRefresh + 1)
        setCaptchaAnswer("")
        setEnableSubmit(true)
    }


    const validateInputs = async () => {
        if (pesilatForm.username.length < 8) {
            setModalData({ ...modalData, isOpen: true, title: t("Please check your data"), main: t("missing_username") })
            return
        }
        if (pesilatForm.email.length < 8) {
            setModalData({ ...modalData, isOpen: true, title: t("Please check your data"), main: t("missing_email") })
            return
        }
        if (pesilatForm.password.length < 8) {
            setModalData({ ...modalData, isOpen: true, title: t("Please check your data"), main: t("missing_password") })
            return
        }
        if (pesilatForm.password != pesilatForm.password2) {
            setModalData({ ...modalData, isOpen: true, title: t("Please check your data"), main: t("password_repeat") })
            return
        }
        if (pesilatForm.fullname.length < 3) {
            setModalData({ ...modalData, isOpen: true, title: t("Please check your data"), main: t("missing_fullname") })
            return
        }
        if (pesilatForm.phone.length < 10) {
            setModalData({ ...modalData, isOpen: true, title: t("Please check your data"), main: t("missing_phone") })
            return
        }
        if (pesilatForm.sabuk < 1) {
            setModalData({ ...modalData, isOpen: true, title: t("Please check your data"), main: t("missing_belt") })
            return
        }
        if (pesilatForm.pendidikan < 1) {
            setModalData({ ...modalData, isOpen: true, title: t("Please check your data"), main: t("missing_education") })
            return
        }
        if (+pesilatForm.joindate < 1968) {
            setModalData({ ...modalData, isOpen: true, title: t("Please check your data"), main: t("missing_joindate") })
            return
        }
        if (pesilatForm.isCoach && pesilatForm.coachLevel < 1){
            setModalData({ ...modalData, isOpen: true, title: t("Please check your data"), main: t("missing_coachlevel") })
            return
        }
        if (pesilatForm.isReferee && pesilatForm.refereeLevel < 1){
            setModalData({ ...modalData, isOpen: true, title: t("Please check your data"), main: t("missing_reflevel") })
            return
        }
        if (pesilatForm.isReferee && +pesilatForm.refFirstUpgrade < 1968){
            setModalData({ ...modalData, isOpen: true, title: t("Please check your data"), main: t("missing_ref_firstup") })
            return
        }
        if (pesilatForm.isReferee && +pesilatForm.refLastUpgrade < 1968){
            setModalData({ ...modalData, isOpen: true, title: t("Please check your data"), main: t("missing_ref_lastup") })
            return
        }

        if (!fileUploaded1) {
            setModalData({ ...modalData, isOpen: true, title: t("Please check your data"), main: t("missing_pic") })
            return
        }
        if (captchaAnswer.length < 6) {
            setModalData({ ...modalData, isOpen: true, title: t("Please check your data"), main: t("missing_captcha") })
            return
        }

        setEnableSubmit(false)
        setProgressModal({ ...progressModal, isOpen: true })
        registerAccount()
    }


    useEffect(() => {
        if (globalContext?.GetLoginData().username !== "") {
            navigate('/postlogin')
        }
    }, [])

    useEffect(() => {
        if (registrationSuccessful) {
            navigate('/login')
        }
        setRegistrationSuccessful(false)
    }, [redirectToLogin])



    return (
        <Box py={"10px"} px={"10px"} maxW='m' borderWidth='0px' borderRadius='lg' overflow='hidden'>

            <InfoModal isOpen={modalData.isOpen} title={modalData.title} mainText={modalData.main} closeFunction={closeModal} />
            <ProgressModal isOpen={progressModal.isOpen} title={progressModal.title} progress={progressModal.progress} closeFunction={closeProgressModal} />

            <SimpleGrid py={"10px"} px={"10px"} borderWidth='2px' borderRadius='lg' overflow='auto' >
                <SimpleGrid columns={[1]} maxW={['340px', '700px']} minW={['340px', '700px']} justifySelf={'center'}>
                    <Center>
                        <Text minW='80px' fontSize='sm'>{t('Country')}</Text>
                        <CountryList selectedFunction={countryIsSelected} />
                    </Center>
                </SimpleGrid>
            </SimpleGrid>

            {selectedCountry > 0 ? (<Pesilat selectedFunction={pesilatIsFilled} refreshCounter={toRefresh}/>) : ''}

            {selectedCountry > 0 && pesilatForm.isAthlete ? (<RegisterPrestasiPage selectedFunction={prestasiIsAdded} />) : ''}

            {selectedCountry > 0 && pesilatForm.isCoach ? (<RegisterCoachExpPage selectedFunction={coachExpIsAdded} />) : ''}

            {selectedCountry > 0 && pesilatForm.isReferee ? (<ImageUpload2 selectedFunction={file2IsUploaded} refreshCounter={toRefresh} existingFile="" />) : ''}

            {selectedCountry > 0 ? (<RantingUpdatePage selectedFunction={rantingExpIsAdded} />) : ''}

            {selectedCountry > 0 ? (<ImageUpload selectedFunction={file1IsUploaded} refreshCounter={toRefresh} existingFile="" />) : ''}

            {selectedCountry > 0 ? (<CaptchaBox selectedFunction={captchaAnswered} refreshIndicator={toRefresh} />) : ''}

            <Center py={"10px"} px={"10px"} maxW='m' borderWidth='0px' borderRadius='lg' overflow='hidden' >
                <VStack minW="300px">

                    {/* {selectedCountry < 1 ? (
                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                            <Text minW="135px">{t('yours_unlisted')}</Text>
                            <Button as={NavLink} to='/contactus' size='xs' colorScheme='green'>{t('Contact Us')}</Button>
                        </Stack>
                    ) : ''} */}
                    <Button colorScheme='blue' isDisabled={!enableSubmit || selectedCountry <= 0} onClick={validateInputs}>{t('Register')}</Button>
                </VStack>
            </Center>


        </Box>

    )

}
