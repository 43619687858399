import React, { useState, useEffect, useContext } from "react"
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Progress,
    Center,
    VStack,
    CircularProgress,
} from '@chakra-ui/react'

type ProgressModalProps = {
    isOpen: boolean
    title: string
    progress: number
    closeFunction: () => void;
};
export default function ProgressModal(props: ProgressModalProps) {
    return (
        <>
            <Modal closeOnOverlayClick={false} isCentered isOpen={props.isOpen} onClose={props.closeFunction}>
                <ModalOverlay
                    bg='blackAlpha.300'
                    backdropFilter='blur(10px) hue-rotate(90deg)'
                />
                <ModalContent>
                    <ModalHeader>{props.title}</ModalHeader>
                    <ModalBody>
                        <Center py={"10px"} px={"10px"} maxW='m' borderWidth='0px' borderRadius='lg' overflow='hidden' >
                            <VStack minW="400px">
                                <CircularProgress size="60px" isIndeterminate color='green.300' />
                                {/* <Progress width={"100%"} hasStripe value={props.progress} maxW={"350px"} maxH={"50px"} /> */}
                            </VStack>
                        </Center>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}