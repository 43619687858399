import React, { useState, useEffect, useContext } from "react"
import { GlobalContextProvider } from "../functions/GlobalContext"
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Text,
    Button,
    Center,
    VStack,
    Stack,
    Input,
    Checkbox,
    Spacer,
    Select,
    Grid,
    GridItem,
} from '@chakra-ui/react'
import Const from "../../Consts"
import CountryList from "../../components/CountryList"
import StateList from "../../components/StateList"
import CityList from "../../components/CityList"
import DistrictList from "../../components/DistrictList"
import RantingList from "../../components/RantingList"
import { useTranslation } from 'react-i18next';
import { sanitizeTextarea, sanitizeEmail, sanitizeName, sanitizeNumber } from "../functions/Common"
import { useNavigate } from 'react-router-dom';
import { Twirp, TwirpProps } from "../functions/Twirp"
import { RantingExp } from "../RantingUpdate";



type PesilatRantingModalProps = {
    isOpen: boolean
    onChangeFunction: (exp: RantingExp) => void;
    closeFunction: () => void;
};
export default function PesilatRantingModal(props: PesilatRantingModalProps) {
    const { t, i18n } = useTranslation();
    const globalContext = useContext(GlobalContextProvider)
    const navigate = useNavigate()

    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const closeModal = () => {
        setModalOpen(false)
    }

    const defaultFormData: RantingExp = {
        key: 0,
        year: "2000",
        position: 0,
        ranting: 0,
        active: false,
    }
    const [formData, setFormData] = useState<RantingExp>(defaultFormData)

    const [selectedCountry, setSelectedCountry] = useState<number>(0)
    const [selectedState, setSelectedState] = useState<number>(0)
    const [selectedCity, setSelectedCity] = useState<number>(0)
    const [selectedDistrict, setSelectedDistrict] = useState<number>(0)
    const [selectedRanting, setSelectedRanting] = useState<number>(0)

    const posisiList = [{ value: 0, label: t("-select-") }, { value: 1, label: t("member") },
    { value: 2, label: t("Coach") }, { value: 3, label: t("Supervisor") },
    ]



    const countryIsSelected = (id: number) => {
        setSelectedCountry(id)
        setSelectedState(0)
        setSelectedCity(0)
        setSelectedDistrict(0)
        setSelectedRanting(0)
    }
    const stateIsSelected = (id: number) => {
        setSelectedState(id)
        setSelectedCity(0)
        setSelectedDistrict(0)
        setSelectedRanting(0)
    }
    const cityIsSelected = (id: number) => {
        setSelectedCity(id)
        setSelectedDistrict(0)
        setSelectedRanting(0)
    }
    const districtIsSelected = (id: number) => {
        setSelectedDistrict(id)
        setSelectedRanting(0)
    }
    const rantingIsSelected = (id: number) => {
        setFormData({ ...formData, ranting: id })
        setSelectedRanting(id)
    }


    useEffect(() => {
        setSelectedCountry(0)
        setSelectedState(0)
        setSelectedCity(0)
        setSelectedDistrict(0)
        setSelectedRanting(0)
        setFormData(defaultFormData)
    }, [props.isOpen])


    const saveAndClose = () => {
        props.onChangeFunction(formData)
        props.closeFunction()
    }

    return (
        <>
            <Modal closeOnOverlayClick={false} size='xl' isCentered isOpen={props.isOpen} onClose={props.closeFunction}>
                <ModalOverlay
                    bg='blackAlpha.300'
                    backdropFilter='blur(10px) hue-rotate(90deg)'
                />
                <ModalContent>
                    <ModalHeader>{t('add_pesilat_ranting2')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Center overflow='hidden' >
                            <VStack spacing='0px'>
                                <VStack maxW={['xs', 'sm']}>

                                    <Grid py='2px' templateColumns='repeat(2, 1fr)' gap={0} alignItems='center'>
                                        <Text fontSize={'sm'}>{t('Country')}</Text>
                                        <CountryList selectedFunction={countryIsSelected} />
                                    </Grid>
                                    {selectedCountry > 0 ? (
                                        <Grid py='2px' templateColumns='repeat(2, 1fr)' gap={0} alignItems='center'>
                                            <Text fontSize={'sm'}>{t('State')}</Text>
                                            <StateList parentId={selectedCountry} selectedFunction={stateIsSelected} />
                                        </Grid>
                                    ) : ''}

                                    {selectedState > 0 ? (
                                        <Grid py='2px' templateColumns='repeat(2, 1fr)' gap={0} alignItems='center'>
                                            <Text fontSize={'sm'}>{t('City')}</Text>
                                            <CityList parentId={selectedState} selectedFunction={cityIsSelected} />
                                        </Grid>)
                                        : ''}

                                    {selectedCity > 0 ? (
                                        <Grid py='2px' templateColumns='repeat(2, 1fr)' gap={0} alignItems='center'>
                                            <Text fontSize={'sm'}>{t('District')}</Text>
                                            <DistrictList parentId={selectedCity} selectedFunction={districtIsSelected} />
                                        </Grid>
                                    ) : ''}

                                    {selectedDistrict > 0 ? (
                                        <Grid py='2px' templateColumns='repeat(2, 1fr)' gap={0} alignItems='center'>
                                            <Text fontSize={'sm'}>{t('Ranting')}</Text>
                                            <RantingList parentId={selectedDistrict} selectedFunction={rantingIsSelected} />
                                        </Grid>
                                    ) : ''}

                                    <Spacer minH='10px' />
                                    {selectedRanting > 0 ? (
                                        <Grid py='2px' templateColumns='repeat(2, 1fr)' gap={0} alignItems='center'>
                                            <Text fontSize={'sm'}>{t('Year')}</Text>
                                            <Input minW={['158px', '192px']} size={'sm'} type='text' value={formData.year} onChange={(e) => setFormData({ ...formData, year: sanitizeNumber(e.target.value) })} maxLength={4} />
                                        </Grid>
                                    ) : ''}

                                    {selectedRanting > 0 ? (
                                        <Grid py='2px' templateColumns='repeat(2, 1fr)' gap={0} alignItems='center'>
                                            <Text fontSize={'sm'}>{t('Status')}</Text>
                                            <Checkbox minW={['158px', '192px']} size='sm' colorScheme="green" isChecked={formData.active} onChange={(e) => setFormData({ ...formData, active: e.target.checked })}>{t('active member')}</Checkbox>
                                        </Grid>
                                    ) : ''}

                                    {selectedRanting > 0 ? (
                                        <Grid py='2px' templateColumns='repeat(2, 1fr)' gap={0} alignItems='center'>
                                            <Text fontSize={'sm'}>{t('As')}</Text>
                                            <Select minW={['158px', '192px']} size={'sm'} value={formData.position}
                                                onChange={(e) => setFormData({ ...formData, position: +e.target.value })} >
                                                {posisiList.map(({ value, label }) => (
                                                    <option key={value} value={value} >
                                                        {label}
                                                    </option>
                                                ))}
                                            </Select>
                                        </Grid>
                                    ) : ''}

                                </VStack>
                            </VStack>
                        </Center>

                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='blue' isDisabled={formData.position < 1 || selectedRanting <= 0 || +(formData.year) < 1968} onClick={saveAndClose}>{t('Save and Close')}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal >
        </>
    )
}