import React, { useState, useEffect, useContext } from "react"
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Text,
    Button,
} from '@chakra-ui/react'

type ConfirmModalProps = {
    isOpen: boolean
    title: string
    mainText: string
    confirmButtonText: string
    closeButtonText: string 
    closeFunction: () => void;
    confirmFunction: () => void;
};
export default function ConfirmModal(props: ConfirmModalProps) {
    return (
        <>
            <Modal size={"xs"} closeOnOverlayClick={false} isCentered isOpen={props.isOpen} onClose={props.closeFunction}>
                <ModalContent>
                    <ModalHeader fontSize={'sm'}>{props.title}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody fontSize={'sm'}>
                        <Text>{props.mainText}</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button size='sm' onClick={props.confirmFunction}>{props.confirmButtonText}</Button>
                        <Text w='10px'/>
                        <Button size='sm' colorScheme='blue' onClick={props.closeFunction}>{props.closeButtonText}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}