import React, { useState, useEffect, useContext, useRef } from "react"
import { GlobalContextProvider } from "../components/functions/GlobalContext"
import {
    Box,
    Text,
    VStack,
    Stack,
    Center,
    Input, InputGroup, InputRightElement,
    Button,
    Image,
    Progress,
    Spacer,
    Tooltip,
} from "@chakra-ui/react"
import { AiFillCheckCircle, AiFillCloseCircle, AiFillExclamationCircle } from 'react-icons/ai'
import InfoModal from "../components/modals/InfoModal"
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Const from "../Consts";

type RouteParams = {
    userid: string
}


type ImageUploadProps = {
    existingFile: string
    selectedFunction: (chosen: File | null) => void
    refreshCounter: number
};
export default function ImageUpload(props: ImageUploadProps) {
    const { t, i18n } = useTranslation();
    const globalContext = useContext(GlobalContextProvider)
    const params = useParams<RouteParams>();
    const navigate = useNavigate()

    const [usecase, setUsecase] = useState(0) //three ways: 0) as register form   1) as edit   2) as view from other user
    const [existingFile, setExistingFile] = useState('')
    const refInputFile = useRef<HTMLInputElement | null>(null)

    const [preview, setPreview] = useState('')
    const [error, setError] = useState<string>(t('missing_pic'))


    const [modalData, setModalData] = useState({
        title: "",
        main: "",
        isOpen: false,
    })
    const closeModal = () => {
        setModalData({ ...modalData, isOpen: false })
    }


    useEffect(() => {
        //reset file uploader
        if (refInputFile.current) {
            setPreview("")
            refInputFile.current.value = ""
            refInputFile.current.type = "text"
            refInputFile.current.type = "file"
        }

        if (globalContext?.GetLoginData().username !== "") {

            const userId = globalContext?.GetLoginData().userId || 0
            const pesilatID = +(params.userid || userId)
            if (pesilatID > 0) {
                if (globalContext?.GetLoginData().isAdmin) {
                    setUsecase(1) //admin is allowed to edit
                    setError('')
                } else if (pesilatID == globalContext?.GetLoginData().userId) {
                    setUsecase(1) //editing own profile
                    setError('')
                } else {
                    setUsecase(2) //viewing other user profile
                }
            }

        } else {
            setUsecase(0)
        }
    }, [props.refreshCounter])


    useEffect(() => {
        setExistingFile(props.existingFile)
    }, [props.existingFile])


    const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        validateFile(file);
    };
    const validateFile = (file: File | null) => {
        if (!file) {
            setError(t('missing_pic'));
            return
        }

        if (!file.type.startsWith('image/')) {
            setError(t('err_not_image'));
            props.selectedFunction(null)
            return
        } else if (file.size > 1000000) {
            setError(t('err_filesize'));
            props.selectedFunction(null)
            return
        }
        setError('');
        setPreview(URL.createObjectURL(file))
        props.selectedFunction(file)
    };
    const onImageError = () => {
        setError(t('err_inv_image'))
        props.selectedFunction(null)
    }


    return (
        <Center py={"10px"} px={"10px"} maxW='m' borderWidth='2px' borderRadius='lg' overflow='hidden' >
            <InfoModal isOpen={modalData.isOpen} title={modalData.title} mainText={modalData.main} closeFunction={closeModal} />

            <VStack>

                {usecase > 0 && existingFile != "" ? (
                    <VStack>
                        <Text>{t('current_profile_picture')}</Text>
                        <Box h="120px" w="100px" bg={"aqua"}>
                            <Image src={Const.File_Download + existingFile} width={100} height={120} />
                        </Box>
                    </VStack>
                ) : ''}

                {usecase == 0 ? (
                    <Text>{t('upload_profile_picture')}</Text>
                ) : ''}
                {usecase == 1 ? (
                    <Text>{t('update_profile_picture')}</Text>
                ) : ''}

                {usecase < 2 ? (
                    <VStack>
                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                            <Box h="120px" w="100px" bg={"aqua"}>
                                {preview ? <Image onError={onImageError} src={preview} width={100} height={120} /> : ''}
                            </Box>
                        </Stack>

                        <Tooltip hasArrow colorScheme="white" bg='red.300' label={error} closeOnClick={false} isOpen={error != ''}>
                            <InputGroup size={'sm'}>
                                <Input  type='file' ref={refInputFile} onChange={handleFileInput} accept="image/*"></Input>
                                <InputRightElement bgColor={'cyan'}>{(error == '') ? (<AiFillCheckCircle color='#4bc213' />) : (<AiFillCloseCircle color='red' />)}</InputRightElement>
                            </InputGroup>
                        </Tooltip>
                    </VStack>
                ) : ''}


                <Spacer minH='25px' />
            </VStack>
        </Center>

    )

}
