import React, { useState, useEffect, useContext } from "react"
import { GlobalContextProvider } from "../components/functions/GlobalContext"
import {
    Box,
    Text,
    VStack,
    Stack,
    Center,
    Input, InputGroup, InputRightElement,
    Image,
    Tooltip,
} from "@chakra-ui/react"
import InfoModal from "../components/modals/InfoModal"
import { AiFillCheckCircle, AiFillCloseCircle, AiFillExclamationCircle } from 'react-icons/ai'
import axios from 'axios'
import { generateRandomString } from "./functions/Common"
import { useTranslation } from 'react-i18next';
import { sanitizeNumber } from "./functions/Common"
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Const from "../Consts"

type RouteParams = {
    userid: string
}

type CaptchaBoxProps = {
    refreshIndicator: number
    selectedFunction: (id: string, answer: string) => void
};
export default function CaptchaBox(props: CaptchaBoxProps) {
    const { t, i18n } = useTranslation();
    const params = useParams<RouteParams>();
    const navigate = useNavigate()
    const globalContext = useContext(GlobalContextProvider)

    const [usecase, setUsecase] = useState(0) //three ways: 0) as register form   1) as edit   2) as view from other user

    const [originalImgPath, setOriginalImgPath] = useState("")
    const [imgPath, setImgPath] = useState("")
    const [answer, setAnswer] = useState("")
    const [idQuestion, setIdQuestion] = useState("")
    const [captchaError, setCaptchaError] = useState(false)


    const [modalData, setModalData] = useState({
        title: "",
        main: "",
        isOpen: false,
    })
    const closeModal = () => {
        setModalData({ ...modalData, isOpen: false })
    }


    const refreshCaptcha = async () => {

        try {
            const res = await axios.get(Const.Captcha_New,
                {
                    timeout: 5000,
                    headers: { Authorization: "paste here" },
                },

            )

            if (res.hasOwnProperty("data") && res.data) {
                // setModalData({ ...modalData, isOpen: true, title: "success", main: "success" })
                setIdQuestion(res.data)
                const img = Const.Captcha_Download + res.data + ".png"
                setImgPath(img)
                setOriginalImgPath(img)
            } else {
                console.log(res)
                throw new Error(t("unknown error"))
            }

        } catch (err) {
            console.log(err)
            setModalData({ ...modalData, isOpen: true, title: "captcha failed", main: "check console" })
        }
        setAnswer("")
    }

    useEffect(() => {

        if (globalContext?.GetLoginData().username !== "") {

            const userId = globalContext?.GetLoginData().userId || 0
            const pesilatID = +(params.userid || userId)
            if (pesilatID > 0) {
                if (globalContext?.GetLoginData().isAdmin) {
                    refreshCaptcha()
                    setUsecase(1) //admin is allowed to edit
                } else if (pesilatID == globalContext?.GetLoginData().userId) {
                    refreshCaptcha()
                    setUsecase(1) //editing own profile
                } else {
                    setUsecase(2) //viewing other user profile
                }
            }

        } else {
            refreshCaptcha()
            setUsecase(0)
        }
    }, [])


    useEffect(() => {

        refreshCaptcha()
    }, [props.refreshIndicator])

    const captchaClicked = async () => {
        if (captchaError) {
            refreshCaptcha()
            setCaptchaError(false)
        } else {
            const random = generateRandomString(16)
            setImgPath(originalImgPath + "?reload=" + random)
        }
    }

    const answerEntered = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAnswer(sanitizeNumber(event.target.value))
        props.selectedFunction(idQuestion, event.target.value)
    }

    const captchImageError = () => {
        setCaptchaError(true)
    }

    return (

        <>
            {usecase < 2 ? (

                <Center py={"10px"} px={"10px"} maxW='m' borderWidth='2px' borderRadius='lg' overflow='hidden' >
                    <InfoModal isOpen={modalData.isOpen} title={modalData.title} mainText={modalData.main} closeFunction={closeModal} />

                    <VStack>
                        <Center w="200px" h="50px" bg={"aqua"} onClick={captchaClicked}>
                            <Image src={imgPath} width={200} height={50} onError={captchImageError} />
                        </Center>
                        <Text fontSize={'xs'}>{t('hard_see')}</Text>
                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                            <Text fontSize='sm' minW="120px">{t('Enter Numbers')}</Text>
                            <Tooltip hasArrow colorScheme="white" bg='red.300' label={t('missing_captcha')} closeOnClick={false} isOpen={answer.length < 6}>
                                <InputGroup maxW={'130px'}>
                                    <Input type='text' value={answer} onChange={answerEntered} maxLength={6} />
                                    <InputRightElement bgColor={'cyan'}>{(answer.length >= 6) ? (<AiFillCheckCircle color='#4bc213' />) : (<AiFillCloseCircle color='red' />)}</InputRightElement>
                                </InputGroup>
                            </Tooltip>
                        </Stack>

                    </VStack>
                </Center>
            ) : ''}
        </>)

}
