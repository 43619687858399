import React, { useState, useEffect, useContext } from "react"
import {
  Box,
  Text,
  VStack,
  Stack,
  Center,
  FormControl,
  Input,
  Button,
} from "@chakra-ui/react"
import { Tabs, TabList, TabPanels, Tab, TabPanel, TabIndicator } from '@chakra-ui/react'
import InfoModal from "../components/modals/InfoModal"
import Const from "../Consts"
import { Twirp, TwirpProps } from "../components/functions/Twirp"
import { GlobalContextProvider } from "../components/functions/GlobalContext"
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EmptyPage } from "./Empty"

export default function AdministrationPage() {
  const globalContext = useContext(GlobalContextProvider)
  const { t, i18n } = useTranslation();
  const navigate = useNavigate()

  useEffect(() => {
    let username = globalContext?.GetLoginData().username || ""
    if (username === "") {
        navigate('/login')
    }
}, [])

  return (
    <Box py={"10px"} px={"10px"} maxW='m' borderWidth='0px' borderRadius='lg' overflow='hidden'>

      <Tabs variant={"enclosed-colored"}>
        <TabList>
          <Tab>{t('Organization')}</Tab>
          <Tab>{t('Access Control')}</Tab>
          <Tab>{t('History')}</Tab>
        </TabList>
        <TabIndicator
          mt="-1.5px"
          height="2px"
          bg="blue.500"
          borderRadius="1px"
        />
        <TabPanels>
          <TabPanel>
            <p>Coming Soon!</p>
          </TabPanel>
          <TabPanel>
            <p>Coming Soon!</p>
          </TabPanel>
          <TabPanel>
            <p>Coming Soon!</p>
          </TabPanel>
        </TabPanels>
      </Tabs>

    </Box>
  )
}
