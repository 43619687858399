import React, { useState, useEffect } from "react"


export type LoginData = {
    username: string,
    isAdmin: boolean,
    userId: number,
    level: number,
    token: string,
    language: string,
}
export type GlobalContextType = {
    GetLoginData: () => LoginData
    LogIn: (username: string, token: string, userId: number, level: number, isAdmin: boolean) => void
    IsLoggedIn: () => boolean
    Logout: () => void
    GetLanguage: () => string 
    SetLanguage: (lang: string) => void
};

export const GlobalContextProvider = React.createContext<GlobalContextType | null>(null)

interface ReactProps {
    children: React.ReactNode;
}
const GlobalContext: React.FC<ReactProps> = ({ children }) => {

    const defaultLoginData: LoginData = { username: "", token: "", language: "id", userId: 0, level: 99, isAdmin: false}

    function getLoginDataFromStorage() {
        const loginDataParsed = JSON.parse(localStorage.getItem("loginData")||'{}')
        return loginDataParsed || defaultLoginData
    }


    const [loginData, setLoginData] = useState<LoginData>(getLoginDataFromStorage())

    const GetLoginData = () => {
        return loginData
    }

    const Logout = () => {
        setLoginData(defaultLoginData)
    }

    const LogIn = (pUsername: string, pToken: string, pUserId: number, pLevel: number, pAdmin: boolean) => {
        const newLoginData: LoginData = { username: pUsername, token: pToken, isAdmin: pAdmin,
            language: loginData.language, userId: pUserId, level: pLevel}
        setLoginData(newLoginData)
    }

    const IsLoggedIn = () => {
        return loginData.token != ""
    }

    const GetLanguage = () => {
        return loginData.language
    }

    const SetLanguage = (lang: string) => {
        setLoginData({...loginData, language: lang})
    }


    useEffect(() => {
        // storing items everytime change is detected in login object
        const temp = JSON.stringify(loginData)
        localStorage.setItem("loginData", temp)
    }, [loginData])


    return <GlobalContextProvider.Provider value={{ GetLoginData, LogIn, IsLoggedIn, Logout, GetLanguage, SetLanguage }}>{children}</GlobalContextProvider.Provider>;

}
export default GlobalContext
