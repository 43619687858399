import React, { useState, useEffect, useContext, useRef } from "react"
import { GlobalContextProvider } from "../components/functions/GlobalContext"
import {
    Text,
    VStack,
    Center,
    Input,
    Link,
    Button,
    Spacer,
} from "@chakra-ui/react"
import InfoModal from "../components/modals/InfoModal"
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

type RouteParams = {
    userid: string
}


type ImageUploadProps = {
    existingFile: string
    selectedFunction: (chosen: File | null) => void
    refreshCounter: number
};
export default function ImageUpload2(props: ImageUploadProps) {
    const { t, i18n } = useTranslation();
    const globalContext = useContext(GlobalContextProvider)
    const params = useParams<RouteParams>();
    const navigate = useNavigate()

    const [usecase, setUsecase] = useState(0) //three ways: 0) as register form   1) as edit   2) as view from other user
    const [existingFile, setExistingFile] = useState('')
    const refInputFile = useRef<HTMLInputElement | null>(null)

    const [error, setError] = useState<string>('')


    const [modalData, setModalData] = useState({
        title: "",
        main: "",
        isOpen: false,
    })
    const closeModal = () => {
        setModalData({ ...modalData, isOpen: false })
    }


    useEffect(() => {
        if (refInputFile.current) {
            refInputFile.current.value = "";
            refInputFile.current.type = "text";
            refInputFile.current.type = "file";
        }

        if (globalContext?.GetLoginData().username !== "") {

            const userId = globalContext?.GetLoginData().userId || 0
            const pesilatID = +(params.userid || userId)
            if (pesilatID > 0) {
                if (globalContext?.GetLoginData().isAdmin) {
                    setUsecase(1) //admin is allowed to edit
                } else if (pesilatID == globalContext?.GetLoginData().userId) {
                    setUsecase(1) //editing own profile
                } else {
                    setUsecase(2) //viewing other user profile
                }
            }

        } else {
            setUsecase(0)
        }
    }, [props.refreshCounter])


    useEffect(() => {
        setExistingFile(props.existingFile)
    }, [props.existingFile])



    const validateFile = (file: File | null) => {
        if (!file) {
            return
        }

        if (!file.type.startsWith('image/') && file.type != 'application/pdf') {
            setError(t('err_only_pdf_image'));
            props.selectedFunction(null)
            return
        } else if (file.size > 1000000) {
            setError(t('err_filesize'));
            props.selectedFunction(null)
            return
        }
        setError('');
        props.selectedFunction(file)
    };
    const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        validateFile(file);
    };


    return (<>
        {usecase < 2 ? (

            <Center py={"10px"} px={"10px"} maxW='m' borderWidth='2px' borderRadius='lg' overflow='hidden' >
                <InfoModal isOpen={modalData.isOpen} title={modalData.title} mainText={modalData.main} closeFunction={closeModal} />

                <VStack>


                    {usecase == 1 && existingFile != "" ? (
                        <VStack>
                            <Button colorScheme='green' size='sm' as={Link} href={"/file/download/" + existingFile}
                                target="_blank" rel="noopener noreferrer">{t('download_existing_certificate')}
                            </Button>
                            <Text fontSize={'sm'}>{t('update_certificate')}</Text>
                        </VStack>
                    ) : ''}
                    {usecase == 1 && existingFile == "" ? (
                        <Text fontSize={'sm'}>{t('upload_certificate')}</Text>
                    ) : ''}
                    {usecase == 0 ? (
                        <Text fontSize={'sm'}>{t('upload_certificate')}</Text>

                    ) : ''}

                    <Input fontSize={'sm'} type='file' ref={refInputFile} onChange={handleFileInput} accept="image/*, application/pdf"></Input>

                    <VStack maxW={"330px"}>
                        {error != '' && (<Text colorScheme="red">Error: {error}</Text>)}
                    </VStack>

                    <Spacer minH='25px' />
                </VStack >
            </Center >
        ) : ''}

    </>
    )

}
