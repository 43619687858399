import React, { useState, useEffect, useContext } from "react"
import { GlobalContextProvider } from "../components/functions/GlobalContext"
import {
    Box,
    Text,
    VStack, HStack,
    Stack,
    Center,
    FormControl,
    Input,
    Button,
    Select,
    Flex,
    Radio, RadioGroup,
    Checkbox,
    Spacer,
    Grid, GridItem, SimpleGrid, InputGroup, InputRightElement, Tooltip,
} from "@chakra-ui/react"
import DatePicker from 'react-datepicker';
import { AiFillCheckCircle, AiFillCloseCircle, AiFillExclamationCircle } from 'react-icons/ai'
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment"
import { useTranslation } from 'react-i18next';
import { sanitize, sanitizeEmail, sanitizeName, sanitizeNumber, sanitizeUsername } from "./functions/Common";
import InfoModal from "./modals/InfoModal";
import { Twirp, TwirpProps } from "../components/functions/Twirp"
import Const from "../Consts";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

type PesilatProps = {
    refreshCounter?: number, //for example purpose, no longer used. To show how to do defaultProps
    selectedFunction: (enteredData: PesilatFields) => void
} & typeof defaultProps
const defaultProps = {
    refreshCounter: 0
}


type RouteParams = {
    userid: string
}

export type PesilatFields = {
    pesilatId: number,
    countryId: number,
    fullname: string,
    email: string,
    username: string,
    password: string,
    password2: string,
    birthdate: Date,
    pendidikan: number,
    sabuk: number,
    joindate: string,
    sex: string,
    phone: string,
    isAthlete: boolean,
    isCoach: boolean,
    isReferee: boolean,
    isOther: boolean,
    otherProfession: string,
    coachLevel: number,
    refereeLevel: number,
    refFirstUpgrade: string,
    refLastUpgrade: string,
    file1Hash: string,
    file2Hash: string,
}
export default function Pesilat(props: PesilatProps) {
    const globalContext = useContext(GlobalContextProvider)
    const { t, i18n } = useTranslation();
    const params = useParams<RouteParams>();
    const navigate = useNavigate()

    const [usecase, setUsecase] = useState(0) //three ways: 0) as register form   1) as edit   2) as view from other user

    const [modalData, setModalData] = useState({
        title: "",
        main: "",
        isOpen: false,
    })
    const closeModal = () => {
        setModalData({ ...modalData, isOpen: false })
    }


    const [formData, setFormData] = useState<PesilatFields>({
        pesilatId: 0,
        countryId: 0,
        fullname: "",
        email: "",
        username: "",
        password: "",
        password2: "",
        birthdate: moment("01-01-1990", "DD-MM-YYYY").toDate(),
        pendidikan: 0,
        sabuk: 0,
        joindate: "1990",
        sex: "m",
        phone: "",
        isAthlete: false,
        isCoach: false,
        isReferee: false,
        isOther: false,
        otherProfession: "",
        coachLevel: 0,
        refereeLevel: 0,
        refFirstUpgrade: "1990",
        refLastUpgrade: "1990",
        file1Hash: "",
        file2Hash: "",
    })


    const pendidikanList = [{ value: 0, label: t("-select-") },
    { value: 1, label: t("Pre-school") }, { value: 2, label: t("Elementary School") }, { value: 3, label: t("High School") }, { value: 4, label: t("Diploma") },
    { value: 5, label: t("Under Graduate") }, { value: 6, label: t("Master") }, { value: 7, label: t("Doctoral") },
    ]
    const onPendidikanChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value
        setFormData({ ...formData, pendidikan: +value })
    }
    const sabukList = [{ value: 0, label: t("-select-") },
    { value: 1, label: t("Black") }, { value: 2, label: t("Blue") }, { value: 3, label: t("Blue Belati 1") }, { value: 4, label: t("Blue Belati 2") },
    { value: 5, label: t("Green") }, { value: 6, label: t("Green Belati 1") }, { value: 7, label: t("Green Belati 2") },
    { value: 8, label: t("Brown") }, { value: 9, label: t("Brown Belati 1") }, { value: 10, label: t("Brown Belati 2") },
    { value: 11, label: t("Purple") }, { value: 12, label: t("Red") }, { value: 13, label: t("Orange") },
    { value: 14, label: t("Yellow") }, { value: 15, label: t("White") }
    ]

    const levelList = [{ value: 0, label: t("-select-") },
    { value: 1, label: t("Ranting") },
    { value: 2, label: t("City 1") }, { value: 3, label: t("City 2") }, { value: 4, label: t("City 3") },
    { value: 5, label: t("Province 1") }, { value: 6, label: t("Province 2") }, { value: 7, label: t("Province 3") },
    { value: 8, label: t("National 1") }, { value: 9, label: t("National 2") }, { value: 10, label: t("National 3") },
    { value: 11, label: t("International 1") }, { value: 12, label: t("International 2") }, { value: 13, label: t("International 3") },
    ]


    const loadData = async (idToSearch: number) => {

        const tprops: TwirpProps = {
            url: Const.API_ReadPesilat, headers: { Authorization: globalContext?.GetLoginData().token }, timeout: 5000,
            data: { id: idToSearch }
        }
        const result = await Twirp(tprops)
        if (result.state == 2) {//other error
            setModalData({ ...modalData, isOpen: true, title: result.axiosError.code, main: result.axiosError.message })
        } else if (result.state == 1) {//twirp controlled error
            let msg = result.axiosError.response?.data?.msg || result.axiosError.message
            setModalData({ ...modalData, isOpen: true, title: result.axiosError.code, main: msg })

            if (result.axiosError.response?.status == 401) {
                navigate('/logout')
            }
        } else { //successful
            setFormData({
                ...formData, pesilatId: result.data.id, countryId: result.data.country_id,
                fullname: result.data.nama, username: result.data.username, email: result.data.email,
                birthdate: moment.unix((+result.data.tanggal_lahir)).toDate(), pendidikan: result.data.pendidikan,
                sabuk: result.data.sabuk, joindate: "" + result.data.tahun_masuk,
                sex: result.data.kelamin, phone: result.data.telepon, isAthlete: result.data.athlete,
                isCoach: result.data.coach as boolean, isReferee: result.data.referee, coachLevel: result.data.coach_level,
                otherProfession: result.data.other_profession, isOther: result.data.other_profession !== "",
                refereeLevel: result.data.referee_level,
                refFirstUpgrade: "" + result.data.referee_first_upgrade,
                refLastUpgrade: "" + result.data.referee_last_upgrade,
                file1Hash: result.data.file1, file2Hash: result.data.file2,
            })
        }
    }


    // useEffect(() => {

    //     if (globalContext?.GetLoginData().username !== "") {

    //         const userId = globalContext?.GetLoginData().userId || 0
    //         const pesilatID = +(params.userid || userId)
    //         if (pesilatID > 0) {
    //             if (globalContext?.GetLoginData().isAdmin) {
    //                 setUsecase(1) //admin is allowed to edit
    //             } else if (pesilatID == globalContext?.GetLoginData().userId) {
    //                 setUsecase(1) //editing own profile
    //             } else {
    //                 setUsecase(2) //viewing other user profile
    //             }
    //         }
    //         loadData(pesilatID)

    //     } else {
    //         setUsecase(0)
    //     }
    // }, [])


    useEffect(() => {

        if (globalContext?.GetLoginData().username !== "") {

            const userId = globalContext?.GetLoginData().userId || 0
            const pesilatID = +(params.userid || userId)
            if (pesilatID > 0) {
                if (globalContext?.GetLoginData().isAdmin) {
                    setUsecase(1) //admin is allowed to edit
                } else if (pesilatID == globalContext?.GetLoginData().userId) {
                    setUsecase(1) //editing own profile
                } else {
                    setUsecase(2) //viewing other user profile
                }
            }
            loadData(pesilatID)

        } else {
            setUsecase(0)
        }
    }, [props.refreshCounter])



    useEffect(() => {
        props.selectedFunction(formData)
    }, [formData])


    const checkUsername = () => {
        const result = formData.username.length >= 8
        return result
    }
    const checkEmail = () => {
        const result = formData.email.length >= 8
        return result
    }
    const checkPassword = () => {
        const result = formData.password.length >= 8
        return result
    }
    const checkPassword2 = () => {
        const result = formData.password2.length >= 8 && formData.password2 == formData.password
        return result
    }
    const checkFullname = () => {
        const result = formData.fullname.length >= 3
        return result
    }
    const checkPendidikan = () => {
        const result = formData.pendidikan > 0
        return result
    }
    const checkBelt = () => {
        const result = formData.sabuk > 0
        return result
    }
    const checkJoindate = () => {
        const result = +formData.joindate >= 1968
        return result
    }
    const checkPhone = () => {
        const result = formData.phone.length >= 9
        return result
    }
    const checkLevelCoach = () => {
        const result = formData.coachLevel > 0
        return result
    }
    const checkLevelReferee = () => {
        const result = formData.refereeLevel > 0
        return result
    }
    const checkRefDate1 = () => {
        const result = +formData.refFirstUpgrade >= 1968
        return result
    }
    const checkRefDate2 = () => {
        const result = +formData.refLastUpgrade >= 1968
        return result
    }


    return (
        <SimpleGrid py={"10px"} px={"10px"} borderWidth='2px' borderRadius='lg' overflow='auto' >
            <InfoModal isOpen={modalData.isOpen} title={modalData.title} mainText={modalData.main} closeFunction={closeModal} />
            <SimpleGrid columns={[1]} maxW={['320px', '700px']} minW={['320px', '700px']} justifySelf={'center'}>
                <SimpleGrid columns={[1, 2]}>

                    <SimpleGrid columns={1} maxW={['250px']} justifySelf={'center'}>
                        <Text mt={'10px'} fontSize='sm'>{t('Username')}</Text>
                        <Tooltip hasArrow colorScheme="white" bg='red.300' label={t('missing_username')} closeOnClick={false} isDisabled={checkUsername()}>
                            <InputGroup size={'sm'}>
                                <Input minW={['237px']} type='username' isDisabled={usecase > 0} value={formData.username} onChange={(e) => setFormData({ ...formData, username: sanitizeUsername(e.target.value) })} maxLength={32} />
                                <InputRightElement bgColor={'cyan'}>{checkUsername() ? (<AiFillCheckCircle color='#4bc213' />) : (<AiFillCloseCircle color='red' />)}</InputRightElement>
                            </InputGroup>
                        </Tooltip>
                    </SimpleGrid>

                    {usecase < 2 ? (
                        <SimpleGrid columns={1} maxW={['250px']} justifySelf={'center'}>
                            <Text mt={'10px'} fontSize='sm'>{t('E-mail')}</Text>
                            <Tooltip hasArrow colorScheme="white" bg='red.300' label={t('missing_email')} closeOnClick={false} isDisabled={checkEmail()}>
                                <InputGroup size={'sm'}>
                                    <Input minW={['237px']} type='text' size='sm' isDisabled={usecase > 1} value={formData.email} onChange={(e) => setFormData({ ...formData, email: sanitizeEmail(e.target.value) })} maxLength={64} />
                                    <InputRightElement bgColor={'cyan'}>{checkEmail() ? (<AiFillCheckCircle color='#4bc213' />) : (<AiFillCloseCircle color='red' />)}</InputRightElement>
                                </InputGroup>
                            </Tooltip>
                        </SimpleGrid>
                    ) : ''}

                    {usecase < 2 ? (
                        <SimpleGrid columns={1} maxW={['250px']} justifySelf={'center'}>
                            <Text mt={'10px'} fontSize='sm'>{t('Password')}</Text>
                            <Tooltip hasArrow colorScheme="white" bg='red.300' label={t('missing_password')} closeOnClick={false} isDisabled={checkPassword()}>
                                <InputGroup size={'sm'}>
                                    <Input minW={['237px']} type='password' size='sm' value={formData.password} onChange={(e) => setFormData({ ...formData, password: e.target.value })} maxLength={32} />
                                    <InputRightElement bgColor={'cyan'}>{checkPassword() ? (<AiFillCheckCircle color='#4bc213' />) : (<AiFillCloseCircle color='red' />)}</InputRightElement>
                                </InputGroup>
                            </Tooltip>
                        </SimpleGrid>
                    ) : ''}

                    {usecase < 2 ? (
                        <SimpleGrid columns={1} maxW={['250px']} justifySelf={'center'}>
                            <Text mt={'10px'} fontSize='sm'>{t('Repeat Password')}</Text>
                            <Tooltip hasArrow colorScheme="white" bg='red.300' label={t('password_repeat')} closeOnClick={false} isDisabled={checkPassword2()}>
                                <InputGroup size={'sm'}>
                                    <Input minW={['237px']} type='password' size='sm' value={formData.password2} onChange={(e) => setFormData({ ...formData, password2: e.target.value })} maxLength={32} />
                                    <InputRightElement bgColor={'cyan'}>{checkPassword2() ? (<AiFillCheckCircle color='#4bc213' />) : (<AiFillCloseCircle color='red' />)}</InputRightElement>
                                </InputGroup>
                            </Tooltip>
                        </SimpleGrid>
                    ) : ''}

                    <SimpleGrid columns={1} maxW={['250px']} justifySelf={'center'}>
                        <Text mt={'10px'} fontSize='sm'>{t('Full Name')}</Text>
                        <Tooltip hasArrow colorScheme="white" bg='red.300' label={t('missing_fullname')} closeOnClick={false} isDisabled={checkFullname()}>
                            <InputGroup size={'sm'}>
                                <Input minW={['237px']} type='text' size='sm' isDisabled={usecase > 0} value={formData.fullname} onChange={(e) => setFormData({ ...formData, fullname: sanitizeName(e.target.value) })} maxLength={64} />
                                <InputRightElement bgColor={'cyan'}>{checkFullname() ? (<AiFillCheckCircle color='#4bc213' />) : (<AiFillCloseCircle color='red' />)}</InputRightElement>
                            </InputGroup>
                        </Tooltip>
                    </SimpleGrid>

                    {usecase < 2 ? (
                        <SimpleGrid columns={1} maxW={['250px']} justifySelf={'center'}>
                            <Text mt={'10px'} fontSize='sm'>{t('Birth Date')}</Text>
                            <Tooltip hasArrow colorScheme="white" bg='red.300' label={t('birthdate_warning')} closeOnClick={false}>
                                <InputGroup size={'sm'}>
                                    <Input size='sm' disabled={usecase > 0} type={"date"} value={moment(formData.birthdate).format("YYYY-MM-DD")} minW={['237px']}
                                        onChange={(e) => {
                                            setFormData({ ...formData, birthdate: moment(e.target.value, "YYYY-MM-DD").toDate() || moment("01-01-1990", "DD-MM-YYYY").toDate() })
                                        }}
                                    />
                                    <InputRightElement bgColor={'cyan'}><AiFillExclamationCircle color='#4bc213' /></InputRightElement>
                                </InputGroup>
                            </Tooltip>
                        </SimpleGrid>
                    ) : ''}


                    <SimpleGrid columns={1} maxW={['250px']} justifySelf={'center'}>
                        <Text mt={'10px'} fontSize={'sm'}>{t('Education')}</Text>
                        <Tooltip hasArrow colorScheme="white" bg='red.300' label={t('missing_education')} closeOnClick={false} isDisabled={checkPendidikan()}>
                            <InputGroup size={'sm'}>
                                <Select size='sm' minW={['237px']} isDisabled={usecase > 1} onChange={onPendidikanChange} value={formData.pendidikan}>
                                    {pendidikanList.map(({ value, label }) => (
                                        <option key={value} value={value} >
                                            {label}
                                        </option>
                                    ))}
                                </Select>
                                <InputRightElement bgColor={'cyan'}>{checkPendidikan() ? (<AiFillCheckCircle color='#4bc213' />) : (<AiFillCloseCircle color='red' />)}</InputRightElement>
                            </InputGroup>
                        </Tooltip>
                    </SimpleGrid>
                    <SimpleGrid columns={1} maxW={['250px']} justifySelf={'center'}>
                        <Text mt={'10px'} fontSize={'sm'}>{t('Belt')}</Text>
                        <Tooltip hasArrow colorScheme="white" bg='red.300' label={t('missing_belt')} closeOnClick={false} isDisabled={checkBelt()}>
                            <InputGroup size={'sm'}>
                                <Select size='sm' minW={['237px']} value={formData.sabuk} isDisabled={usecase > 1}
                                    onChange={(e) => setFormData({ ...formData, sabuk: +e.target.value })} >
                                    {sabukList.map(({ value, label }) => (
                                        <option key={value} value={value} >
                                            {label}
                                        </option>
                                    ))}
                                </Select>
                                <InputRightElement bgColor={'cyan'}>{checkBelt() ? (<AiFillCheckCircle color='#4bc213' />) : (<AiFillCloseCircle color='red' />)}</InputRightElement>
                            </InputGroup>
                        </Tooltip>
                    </SimpleGrid>
                    <SimpleGrid columns={1} maxW={['250px']} justifySelf={'center'}>
                        <Text mt={'10px'} fontSize={'sm'}>{t('joined_kpsn')}</Text>
                        <Tooltip hasArrow colorScheme="white" bg='red.300' label={t('missing_joindate')} closeOnClick={false} isDisabled={checkJoindate()}>
                            <InputGroup size={'sm'}>
                                <Input minW={['237px']} type='text' size='sm' isDisabled={usecase > 1} value={formData.joindate} onChange={(e) => setFormData({ ...formData, joindate: sanitizeNumber(e.target.value) })} maxLength={4} />
                                <InputRightElement bgColor={'cyan'}>{checkJoindate() ? (<AiFillCheckCircle color='#4bc213' />) : (<AiFillCloseCircle color='red' />)}</InputRightElement>
                            </InputGroup>
                        </Tooltip>
                    </SimpleGrid>
                    <SimpleGrid columns={1} maxW={['250px']} justifySelf={'center'}>
                        <Text mt={'10px'} fontSize='sm' borderColor='#eceff4'>{t('Sex')}</Text>
                        <RadioGroup minW={['237px']} isDisabled={usecase > 1} defaultValue='1' value={formData.sex} onChange={(e) => setFormData({ ...formData, sex: e })}>
                            <Stack spacing={4} direction='row'>
                                <Radio size='sm' value='m'>{t('Male')}</Radio>
                                <Radio size='sm' value='f'>{t('Female')}</Radio>
                            </Stack>
                        </RadioGroup>
                    </SimpleGrid>

                    {usecase < 2 ? (
                        <SimpleGrid columns={1} maxW={['250px']} justifySelf={'center'}>
                            <Flex direction={'column'}>
                                <Text mt={'10px'} fontSize='sm' justifySelf={'flex-start'}>{t('Handhone #')}</Text>
                                <Tooltip hasArrow colorScheme="white" bg='red.300' label={t('missing_phone')} closeOnClick={false} isDisabled={checkPhone()}>
                                    <InputGroup size={'sm'} maxW={'237px'}>
                                        <Input minW={['237px']} size='sm' type='text' value={formData.phone} onChange={(e) => setFormData({ ...formData, phone: sanitizeNumber(e.target.value) })} maxLength={16} />
                                        <InputRightElement bgColor={'cyan'}>{checkPhone() ? (<AiFillCheckCircle color='#4bc213' />) : (<AiFillCloseCircle color='red' />)}</InputRightElement>
                                    </InputGroup>
                                </Tooltip>
                            </Flex>
                        </SimpleGrid>
                    ) : ''}


                    <SimpleGrid columns={1} maxW={['250px']} justifySelf={'center'}>
                        <Text mt={'10px'} fontSize='sm' >{t('choose_profession')}</Text>
                        <HStack maxW={'237px'}>
                            <Checkbox size='sm' isDisabled={usecase > 1} minW='110px' maxW='110px' colorScheme="green" isChecked={formData.isAthlete} onChange={(e) => setFormData({ ...formData, isAthlete: e.target.checked })}>{t('Athlete')}</Checkbox>
                            <Checkbox size='sm' isDisabled={usecase > 1} minW='110px' maxW='110px' colorScheme="green" isChecked={formData.isCoach} onChange={(e) => setFormData({ ...formData, isCoach: e.target.checked })}>{t('Coach')}</Checkbox>
                        </HStack>
                        <HStack maxW={'237px'}>
                            <Checkbox size='sm' isDisabled={usecase > 1} minW='110px' maxW='110px' colorScheme="green" isChecked={formData.isReferee} onChange={(e) => setFormData({ ...formData, isReferee: e.target.checked })}>{t('Referee')}</Checkbox>
                            <Checkbox size='sm' isDisabled={usecase > 1} minW='110px' maxW='110px' colorScheme="green" isChecked={formData.isOther} onChange={(e) => setFormData({ ...formData, isOther: e.target.checked })}>{t('Other')}</Checkbox>
                        </HStack>
                    </SimpleGrid>

                    {formData.isOther ? (
                        <SimpleGrid columns={1} maxW={['250px']} justifySelf={'center'}>
                            <Text mt={'10px'} fontSize='sm'  >{t('specify_other')}</Text>
                            <Input size='sm' minW={'237px'} type='text' isDisabled={usecase > 1} value={formData.otherProfession} onChange={(e) => setFormData({ ...formData, otherProfession: sanitize(e.target.value) })} maxLength={32} />
                        </SimpleGrid>
                    ) : ''}

                    {formData.isCoach ? (
                        <SimpleGrid columns={1} maxW={['250px']} justifySelf={'center'}>
                            <Text mt={'10px'} fontSize='sm'  >{t('Coach Level')}</Text>
                            <Tooltip hasArrow colorScheme="white" bg='red.300' label={t('missing_coachlevel')} closeOnClick={false} isDisabled={checkLevelCoach()}>
                                <InputGroup size={'sm'}>
                                    <Select size='sm' minW={['237px']} value={formData.coachLevel} isDisabled={usecase > 1}
                                        onChange={(e) => setFormData({ ...formData, coachLevel: +e.target.value })} >
                                        {levelList.map(({ value, label }) => (
                                            <option key={value} value={value} >
                                                {label}
                                            </option>
                                        ))}
                                    </Select>
                                    <InputRightElement bgColor={'cyan'}>{checkLevelCoach() ? (<AiFillCheckCircle color='#4bc213' />) : (<AiFillCloseCircle color='red' />)}</InputRightElement>
                                </InputGroup>
                            </Tooltip>
                        </SimpleGrid>
                    ) : <></>}

                    {formData.isReferee ? (
                        <SimpleGrid columns={1} maxW={['250px']} justifySelf={'center'}>
                            <Text mt={'10px'} fontSize='sm'  >{t('Referee Level')}</Text>
                            <Tooltip hasArrow colorScheme="white" bg='red.300' label={t('missing_reflevel')} closeOnClick={false} isDisabled={checkLevelReferee()}>
                                <InputGroup size={'sm'}>
                                    <Select size='sm' minW={['237px']} value={formData.refereeLevel} isDisabled={usecase > 1}
                                        onChange={(e) => setFormData({ ...formData, refereeLevel: +e.target.value })} >
                                        {levelList.map(({ value, label }) => (
                                            <option key={value} value={value} >
                                                {label}
                                            </option>
                                        ))}
                                    </Select>
                                    <InputRightElement bgColor={'cyan'}>{checkLevelReferee() ? (<AiFillCheckCircle color='#4bc213' />) : (<AiFillCloseCircle color='red' />)}</InputRightElement>
                                </InputGroup>
                            </Tooltip>
                        </SimpleGrid>
                    ) : <></>}

                    {formData.isReferee ? (
                        <SimpleGrid columns={1} maxW={['250px']} justifySelf={'center'}>
                            <Text mt={'10px'} fontSize='sm'  >{t('First Referee Upgrade')}</Text>
                            <Tooltip hasArrow colorScheme="white" bg='red.300' label={t('missing_ref_firstup')} closeOnClick={false} isDisabled={checkRefDate1()}>
                                <InputGroup size={'sm'}>
                                    <Input minW={['237px']} type='text' size='sm' value={formData.refFirstUpgrade} onChange={(e) => setFormData({ ...formData, refFirstUpgrade: sanitizeNumber(e.target.value) })} maxLength={4} />
                                    <InputRightElement bgColor={'cyan'}>{checkRefDate1() ? (<AiFillCheckCircle color='#4bc213' />) : (<AiFillCloseCircle color='red' />)}</InputRightElement>
                                </InputGroup>
                            </Tooltip>
                        </SimpleGrid>
                    ) : <></>}
                    {formData.isReferee ? (
                        <SimpleGrid columns={1} maxW={['250px']} justifySelf={'center'}>
                            <Text mt={'10px'} fontSize='sm' >{t('Recent Referee Upgrade')}</Text>
                            <Tooltip hasArrow colorScheme="white" bg='red.300' label={t('missing_ref_lastup')} closeOnClick={false} isDisabled={checkRefDate2()}>
                                <InputGroup size={'sm'}>
                                    <Input minW={['237px']} type='text' size='sm' value={formData.refLastUpgrade} onChange={(e) => setFormData({ ...formData, refLastUpgrade: sanitizeNumber(e.target.value) })} maxLength={4} />
                                    <InputRightElement bgColor={'cyan'}>{checkRefDate2() ? (<AiFillCheckCircle color='#4bc213' />) : (<AiFillCloseCircle color='red' />)}</InputRightElement>
                                </InputGroup>
                            </Tooltip>
                        </SimpleGrid>
                    ) : <></>}


                </SimpleGrid>
            </SimpleGrid>
        </SimpleGrid >
    )

}
Pesilat.defaultProps = defaultProps