import axios from 'axios'
import { AxiosError } from "axios"

type TwirpErrorResponse = {
    code: string,
    msg: string
};

export type TwirpProps = {
    timeout: number,
    url: string,
    data: any,
    headers: any,
    
};
type TwirpResult = {
    state: number,
    data: any,
    axiosError: any,
};
export const Twirp = async (props: TwirpProps) => {
    let result : TwirpResult = {state : 0, data: {}, axiosError: {}}
    try {
        const res = await axios.post(props.url,props.data,
            {
                timeout: props.timeout,
                headers: props.headers
            }
        )

        if (res.hasOwnProperty("data") && res.data) {
            //login is successful
            result.data = res.data

        } else {
            console.log(res)
            throw new Error("unknown error, please check console log")
        }

    } catch (err) {
        result.state = 2
        if (axios.isAxiosError(err)) {
            result.state = 1
            const error = err as AxiosError
            result.axiosError = error
        }else{
            result.axiosError = err
        }
    }
    return result
}