import config from './config.json'

const Twirp_Prefix = config.urlapi+"/twirp/kpsn.KPSN"
const Const = {
    API_Register : config.urlapi+"/api/register",
    API_UpdateProfile : config.urlapi+"/api/update/",
    API_Login_URL : Twirp_Prefix + "/Login",
    API_Logout_URL : Twirp_Prefix + "/Logout",
    API_ListCountries : Twirp_Prefix + "/ListCountries",
    API_ListStates : Twirp_Prefix + "/ListStateCountry",
    API_ListCities : Twirp_Prefix + "/ListCityState",
    API_ListDistrict : Twirp_Prefix + "/ListCabangCity",
    API_ListRanting : Twirp_Prefix + "/ListRantingCabang",
    API_ReadCountry : Twirp_Prefix + "/ReadCountry",
    API_ReadPesilat : Twirp_Prefix + "/ReadPesilat",
    API_ReadPrestasi : Twirp_Prefix + "/ReadPrestasi", 
    API_RantingTree : Twirp_Prefix + "/ListRantingTree", 
    API_ListRantingPesilat : Twirp_Prefix + "/ListRantingPesilat", 
    API_ContactUs : Twirp_Prefix + "/ContactUs", 
    Captcha_New : config.urlapi+"/captcha/new",
    Captcha_Download : config.urlapi+"/captcha/download/",
    File_Download : config.urlapi+"/file/download/",

}
export default Const