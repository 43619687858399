import React, { useState, useEffect, useContext } from "react"
import { GlobalContextProvider } from "../components/functions/GlobalContext"
import { useNavigate } from 'react-router-dom';
import { Twirp, TwirpProps } from "../components/functions/Twirp"
import Const from "../Consts";

export default function LogoutPage() {
    const globalContext = useContext(GlobalContextProvider)
    const navigate = useNavigate()


    const onLogout = async () => {

        const props: TwirpProps = {
            url: Const.API_Logout_URL, headers: {}, timeout: 5000,
            data: { token: globalContext?.GetLoginData().token }
        }
        await Twirp(props)
    }


    useEffect(() => {
        onLogout()
        globalContext?.Logout()
        navigate('/login')
    }, [])


    return (<></>)
}
