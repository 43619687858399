export const generateRandomString = (length: number) => {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };


export const fixStringLength = (str: string, max: number) => {
    if (str.length >= max) {
        return str.substring(0, max-1)+".."
    }
    return str
}

export const sanitize = (str: string) => {
  return str.replace(/[`~!#$%^&*()|+\=?;:'"<>\{\}\[\]\\\/]/gi, '')
}

export const sanitizePhoneNumber = (str: string) => {
  return str.replace(/[^0-9+]/gi, '')
}

export const sanitizeNumber = (str: string) => {
  return str.replace(/[^0-9]/gi, '')
}

export const sanitizeUsername = (str: string) => {
  return str.toLocaleLowerCase().replace(/[^0-9a-z]/gi, '')
}

export const sanitizeName = (str: string) => {
  return str.toLocaleLowerCase().replace(/[^a-z ]/gi, '')
}

export const sanitizeEmail = (str: string) => {
  return str.toLocaleLowerCase().replace(/[^0-9a-z.@_-]/gi, '')
}

export const sanitizeTextarea = (str: string) => {
  return str.replace(/[`~#$%^&*()|+\=;:'"<>\{\}\[\]\\\/]/gi, '')
}
