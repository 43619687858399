import React, { useState, useEffect, useContext } from "react"
import {
    Box,
    Text,
    VStack,
    Stack,
    Center,
    FormControl,
    Input,
    Button,
    Spacer,
} from "@chakra-ui/react"
import { GlobalContextProvider } from "../components/functions/GlobalContext"
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { sanitize } from "../components/functions/Common";

export default function PostLoginPage() {
    const globalContext = useContext(GlobalContextProvider)
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()



    useEffect(() => {
        
        let username = globalContext?.GetLoginData().username || ""
        if (username === "") {
            navigate('/login')
        }
    }, [])

    return (
        <Box py={"10px"} px={"10px"} maxW='m' borderWidth='0px' borderRadius='lg' overflow='hidden'>
            <Center py={"10px"} px={"10px"} maxW='m' borderWidth='2px' borderRadius='lg' overflow='hidden' >
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <Text>{t('welcome')}, </Text>
                    <Text><b>{globalContext?.GetLoginData().username}</b></Text>
                </Stack>
            </Center>
        </Box>
    )
}
