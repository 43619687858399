import { useState, useContext, useEffect } from "react"
import { GlobalContextProvider } from "../components/functions/GlobalContext"
import {
    Box,
    Text,
    VStack,
    Stack,
    Center,
    FormControl,
    Input,
    Button,
    Select,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Spacer,
    Grid, GridItem,
    Flex,
    Tooltip,
    Icon, IconButton,
} from "@chakra-ui/react"
import InfoModal from "../components/modals/InfoModal"
import ConfirmModal from "./modals/ConfirmModal"
import { BsFillTrashFill } from 'react-icons/bs';
import { fixStringLength } from "./functions/Common"
import { useTranslation } from 'react-i18next';
import { sanitize, sanitizeNumber } from "./functions/Common";
import { Twirp, TwirpProps } from "../components/functions/Twirp"
import Const from "../Consts";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

type RouteParams = {
    userid: string
}

export type PrestasiType = {
    key: number,
    year: string,
    level: number,
    eventName: string,
    medal: number,
}

type ListType = {
    value: number,
    label: string,
}

type RegisterPrestasiProps = {
    selectedFunction: (prestasi: PrestasiType[]) => void
}

export default function RegisterPrestasiPage(props: RegisterPrestasiProps) {
    const { t, i18n } = useTranslation();
    const globalContext = useContext(GlobalContextProvider)
    const params = useParams<RouteParams>();
    const navigate = useNavigate()

    const [usecase, setUsecase] = useState(0) //three ways: 0) as register form   1) as edit   2) as view from other user

    const [curentKeyNumber, setCurrentKeyNumber] = useState<number>(0)

    const [formData, setFormData] = useState<PrestasiType>({
        key: 0,
        year: "2000",
        level: 0,
        eventName: "",
        medal: 0,
    })
    const [prestasiList, setPrestasiList] = useState<PrestasiType[]>([])

    const levelList: ListType[] = [{ value: 0, label: t("-select-") },
    { value: 1, label: t("Ranting") }, { value: 2, label: t("City") }, { value: 3, label: t("Province") }, { value: 4, label: t("National") },
    { value: 5, label: t("International") },
    ]
    const medalList: ListType[] = [{ value: 0, label: t("-select-") },
    { value: 1, label: t("Bronze") }, { value: 2, label: t("Silver") }, { value: 3, label: t("Gold") }, { value: 4, label: t("Other") },
    ]


    const [modalData, setModalData] = useState({
        title: "",
        main: "",
        isOpen: false,
    })
    const closeModal = () => {
        setModalData({ ...modalData, isOpen: false })
    }

    const [cmData, setCmData] = useState({
        title: "",
        main: "",
        isOpen: false,
        closeText: "",
        confirmText: "",
        actionKey : 0,
    })
    const confirmModalOpens = (key: number) => {
        setCmData({ ...cmData, isOpen: true, actionKey: key, title:t('confirm_requested'), main: t('remove_this_line'),
            closeText: t('Cancel'), confirmText: t('Delete')})              
    }
    const confirmModalIsClosed = () => {
        setCmData({ ...cmData, isOpen: false })
    }
    const confirmModalOkFunction = () => {
        removeFromList(cmData.actionKey)
        setCmData({ ...cmData, isOpen: false })
    }



    const addToPrestasiList = () => {
        if (formData.level < 1 || formData.medal < 1 || formData.eventName.length < 3) {
            return
        }
        setCurrentKeyNumber(curentKeyNumber + 1) //always increase primary key to make it unique per row
        const newData = { key: curentKeyNumber, year: formData.year, level: formData.level, eventName: formData.eventName, medal: formData.medal }
        setPrestasiList([...prestasiList, newData])
        setFormData({
            ...formData,
            year: "2000",
            level: 0,
            eventName: "",
            medal: 0,
        })
        props.selectedFunction([...prestasiList, newData])
    }

    const removeFromList = (key: number) => {
        const newData = prestasiList.filter((p) => p.key !== key);
        setPrestasiList(newData)
        props.selectedFunction([...newData])
    }


    const loadData = async (idToSearch: number) => {

        const tprops: TwirpProps = {
            url: Const.API_ReadPrestasi, headers: { Authorization: globalContext?.GetLoginData().token }, timeout: 5000,
            data: { pesilat_id: idToSearch, type: 1 }
        }
        const result = await Twirp(tprops)
        if (result.state == 2) {//other error
            setModalData({ ...modalData, isOpen: true, title: result.axiosError.code, main: result.axiosError.message })
        } else if (result.state == 1) {//twirp controlled error
            // let msg = result.axiosError.response?.data?.msg || result.axiosError.message
            // setModalData({ ...modalData, isOpen: true, title: result.axiosError.code, main: msg })

            if (result.axiosError.response?.status == 401) {
                navigate('/logout')
            }
        } else { //successful
            if (result.data.prestasiList?.length > 0) {

                let currentKey = curentKeyNumber //always increase primary key to make it unique per row
                let tempPrestasi: PrestasiType[] = []
                result.data.prestasiList.forEach((value: any, index: any) => {
                    const newData = {
                        key: currentKey, year: "" + value.year,
                        level: value.level,
                        eventName: value.event,
                        medal: value.medal
                    }
                    currentKey++
                    tempPrestasi = [...tempPrestasi, newData]
                });
                setCurrentKeyNumber(currentKey)
                setPrestasiList(tempPrestasi)
                props.selectedFunction(tempPrestasi)
            }
        }
    }


    useEffect(() => {

        if (globalContext?.GetLoginData().username !== "") {

            const userId = globalContext?.GetLoginData().userId || 0
            const pesilatID = +(params.userid || userId)
            if (pesilatID > 0) {
                if (globalContext?.GetLoginData().isAdmin) {
                    setUsecase(1) //admin is allowed to edit
                } else if (pesilatID == globalContext?.GetLoginData().userId) {
                    setUsecase(1) //editing own profile
                } else {
                    setUsecase(2) //viewing other user profile
                }
            }
            loadData(pesilatID)

        } else {
            setUsecase(0)
        }
    }, [])



    return (
        <Center py={"10px"} px={"10px"} borderWidth='2px' borderRadius='lg' overflow={"auto"}>
            <InfoModal isOpen={modalData.isOpen} title={modalData.title} mainText={modalData.main} closeFunction={closeModal} />
            <ConfirmModal isOpen={cmData.isOpen} closeFunction={confirmModalIsClosed} closeButtonText={cmData.closeText} confirmButtonText={cmData.confirmText}
                confirmFunction={confirmModalOkFunction}  title={cmData.title} mainText={cmData.main}/>

            <FormControl alignItems={'center'}>

                <VStack>

                    <Text fontSize='sm' minW="100px">{t('athlete_accomplishment')}</Text>

                    {usecase < 2 ? (
                        <VStack maxW={["xs", "sm"]}>
                            <Grid templateColumns='repeat(2, 1fr)' gap={0} alignItems='center'>
                                <Text fontSize='sm'>{t('Year')}</Text>
                                <Input minW={['158px', '192px']} type='text' size='sm' value={formData.year} onChange={(e) => setFormData({ ...formData, year: sanitizeNumber(e.target.value) })} maxLength={4} />
                            </Grid>
                            <Grid templateColumns='repeat(2, 1fr)' gap={0} alignItems='center'>
                                <Text fontSize='sm'>{t('Level')}</Text>
                                <Select minW={['158px', '192px']} size='sm' value={formData.level}
                                    onChange={e => setFormData({ ...formData, level: +e.target.value })}>
                                    {levelList.map(({ value, label }) => (
                                        <option key={value} value={value} >
                                            {label}
                                        </option>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid templateColumns='repeat(2, 1fr)' gap={0} alignItems='center'>
                                <Text fontSize='sm'>{t('Medal')}</Text>
                                <Select minW={['158px', '192px']} size='sm' value={formData.medal}
                                    onChange={e => setFormData({ ...formData, medal: +e.target.value })}>
                                    {medalList.map(({ value, label }) => (
                                        <option key={value} value={value} >
                                            {label}
                                        </option>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid templateColumns='repeat(2, 1fr)' gap={0} alignItems='center'>
                                <Text fontSize='sm'>{t('Event Name')}</Text>
                                <Input minW={['158px', '192px']} size='sm' type='text' value={formData.eventName} onChange={(e) => setFormData({ ...formData, eventName: sanitize(e.target.value) })} maxLength={64} />
                            </Grid>
                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                <Button size='sm' isDisabled={formData.year.length < 4 || formData.eventName.length < 1 || formData.level < 1 || formData.medal < 1}
                                    colorScheme='blue' onClick={addToPrestasiList}>{t('Add to List')}</Button>
                            </Stack>

                        </VStack>
                    ) : ''}


                    <TableContainer>
                        <Table variant='simple' colorScheme='blue' size='sm'>
                            <Thead>
                                <Tr>
                                    <Th fontSize='xs'>{t('Year')}</Th>
                                    <Th>{t('Level')}</Th>
                                    <Th>{t('Event Name')}</Th>
                                    <Th>{t('Medal')}</Th>
                                    {usecase < 2 ? (
                                        <Th ></Th>
                                    ) : ''}

                                </Tr>
                            </Thead>
                            <Tbody>
                                {prestasiList.map(({ key, year, level, eventName, medal }) => (
                                    <Tr key={key}>
                                        <Td fontSize='xs'>{year}</Td>
                                        <Td fontSize='xs'>{levelList[level].label}</Td>
                                        <Td fontSize='xs'><Tooltip label={eventName}>
                                            {fixStringLength(eventName, 14)}</Tooltip>
                                        </Td>
                                        <Td fontSize='xs'>{medalList[medal].label}</Td>
                                        {usecase < 2 ? (
                                            <Td fontSize='xs'>
                                                <Button size='xs' colorScheme='pink' onClick={() => confirmModalOpens(key)}><BsFillTrashFill/></Button>
                                            </Td>
                                        ) : ''}

                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>


                    <Spacer minH='25px' />

                </VStack>
            </FormControl >
        </Center >

    )

}
