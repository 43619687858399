import { useState, useContext, useEffect } from "react"
import { GlobalContextProvider } from "../components/functions/GlobalContext"
import {
    Box,
    Text,
    VStack,
    Stack,
    Center,
    FormControl,
    Input,
    Button,
    Select,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Icon,
    Link,
    Spacer,
    IconButton,
} from "@chakra-ui/react"
import InfoModal from "../components/modals/InfoModal"
import PesilatRantingModal from "./modals/PesilatRantingModal"
import RantingInfoModal from "./modals/RantingInfoModal"
import ConfirmModal from "./modals/ConfirmModal"
import { fixStringLength } from "./functions/Common"
import { useTranslation } from 'react-i18next';
import { sanitize } from "./functions/Common";
import { Twirp, TwirpProps } from "../components/functions/Twirp"
import Const from "../Consts";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { MdOutlineDoNotDisturbAlt } from 'react-icons/md';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { BsFillTrashFill } from 'react-icons/bs';

type RouteParams = {
    userid: string
}

export type RantingToPesilatCreationParam = {
    ranting_id: number
    pesilat_id: number
    year: number
    position: number
    active: boolean
}
export type WrapperRTP = {
    list: RantingToPesilatCreationParam[]
}



export type RantingExp = {
    key: number,
    year: string,
    position: number,
    ranting: number,
    active: boolean,
}

type RantingUpdateProps = {
    selectedFunction: (rantings: RantingExp[]) => void
}

export default function RantingUpdatePage(props: RantingUpdateProps) {
    const { t, i18n } = useTranslation();
    const globalContext = useContext(GlobalContextProvider)
    const params = useParams<RouteParams>();
    const navigate = useNavigate()

    const [usecase, setUsecase] = useState(0) //three ways: 0) as register form   1) as edit   2) as view from other user
    const [addRantingModalOpen, setAddRantingModalOpen] = useState<boolean>(false)
    const [infoRantingModalOpen, setInfoRantingModalOpen] = useState<boolean>(false)
    const [rantingToView, setRantingToView] = useState<number>(0)

    // const [formData, setFormData] = useState<RantingExp>({
    //     key: 0,
    //     year: "2000",
    //     position: 0,
    //     team: "",
    // })
    const [rantingList, setRantingList] = useState<RantingExp[]>([])
    const [curentKeyNumber, setCurrentKeyNumber] = useState<number>(0)
    const posisiList = [{ value: 0, label: t("-select-") }, { value: 1, label: t("member") },
    { value: 2, label: t("Coach") }, { value: 3, label: t("Supervisor") },
    ]

    const [modalData, setModalData] = useState({
        title: "",
        main: "",
        isOpen: false,
    })
    const closeModal = () => {
        setModalData({ ...modalData, isOpen: false })
    }

    const [cmData, setCmData] = useState({
        title: "",
        main: "",
        isOpen: false,
        closeText: "",
        confirmText: "",
        actionKey : 0,
    })
    const confirmModalOpens = (key: number) => {
        setCmData({ ...cmData, isOpen: true, actionKey: key, title:t('confirm_requested'), main: t('remove_this_line'),
            closeText: t('Cancel'), confirmText: t('Delete')})              
    }
    const confirmModalIsClosed = () => {
        setCmData({ ...cmData, isOpen: false })
    }
    const confirmModalOkFunction = () => {
        removeFromList(cmData.actionKey)
        setCmData({ ...cmData, isOpen: false })
    }



    const loadData = async (idToSearch: number) => {

        const tprops: TwirpProps = {
            url: Const.API_ListRantingPesilat, headers: { Authorization: globalContext?.GetLoginData().token }, timeout: 5000,
            data: { id: idToSearch }
        }
        const result = await Twirp(tprops)
        if (result.state == 2) {//other error
            setModalData({ ...modalData, isOpen: true, title: result.axiosError.code, main: result.axiosError.message })
        } else if (result.state == 1) {//twirp controlled error
            // let msg = result.axiosError.response?.data?.msg || result.axiosError.message
            // setModalData({ ...modalData, isOpen: true, title: result.axiosError.code, main: msg })
            if (result.axiosError.response?.status == 401) {
                navigate('/logout')
            }
        } else { //successful
            if (result.data.list?.length > 0) {
                let currentKey = curentKeyNumber //always increase primary key to make it unique per row
                let tempPrestasi: RantingExp[] = []
                result.data.list.forEach((value: any, index: any) => {
                    const newData: RantingExp = {
                        key: currentKey,
                        year: "" + value.year,
                        position: +value.position,
                        active: value.active,
                        ranting: +value.ranting_id,
                    }
                    currentKey++
                    tempPrestasi = [...tempPrestasi, newData]
                });
                setCurrentKeyNumber(currentKey)
                setRantingList(tempPrestasi)
                props.selectedFunction(tempPrestasi)
            }
        }
    }

    const removeFromList = (key: number) => {
        const newData = rantingList.filter((p) => p.key !== key);
        setRantingList(newData)
        props.selectedFunction([...newData])
    }

    const rantingIsAdded = (exp: RantingExp) => {
        exp.key = curentKeyNumber
        setCurrentKeyNumber(curentKeyNumber + 1)
        let tempList = [...rantingList, exp]
        setRantingList(tempList)
        props.selectedFunction(tempList)
    }
    const rantingModalIsClosed = () => {
        setAddRantingModalOpen(false)
    }

    const openInfoRanting = (id: number) => {
        setRantingToView(id)
        setInfoRantingModalOpen(true)
    }
    const infoRantingModalIsClosed = () => {
        setInfoRantingModalOpen(false)
    }



    useEffect(() => {

        if (globalContext?.GetLoginData().username !== "") {

            const userId = globalContext?.GetLoginData().userId || 0
            const pesilatID = +(params.userid || userId)
            if (pesilatID > 0) {
                if (globalContext?.GetLoginData().isAdmin) {
                    setUsecase(1) //admin is allowed to edit
                } else if (pesilatID == globalContext?.GetLoginData().userId) {
                    setUsecase(1) //editing own profile
                } else {
                    setUsecase(2) //viewing other user profile
                }
            }
            loadData(pesilatID)

        } else {
            setUsecase(0)
        }
    }, [])



    return (

        <Center py={"10px"} px={"10px"} maxW='m' borderWidth='2px' borderRadius='lg' overflow='hidden' >
            <InfoModal isOpen={modalData.isOpen} title={modalData.title} mainText={modalData.main} closeFunction={closeModal} />
            <PesilatRantingModal isOpen={addRantingModalOpen} onChangeFunction={rantingIsAdded} closeFunction={rantingModalIsClosed} />
            <RantingInfoModal isOpen={infoRantingModalOpen} closeFunction={infoRantingModalIsClosed} rantingId={rantingToView} />
            <ConfirmModal isOpen={cmData.isOpen} closeFunction={confirmModalIsClosed} closeButtonText={cmData.closeText} confirmButtonText={cmData.confirmText}
                confirmFunction={confirmModalOkFunction}  title={cmData.title} mainText={cmData.main}/>

            <FormControl alignItems={'center'}>

                <VStack>

                    <Text fontSize='sm'>{t('list_ranting')}</Text>
                    {usecase < 2 ? (
                        <VStack>
                            <Button size='xs' colorScheme='blue' onClick={(e) => { setAddRantingModalOpen(true) }}>{t('add_pesilat_ranting')}</Button>
                        </VStack>
                    ) : ''}


                    <TableContainer w='400px'>
                        <Table variant='simple' colorScheme='blue' size='sm'>
                            <Thead>
                                <Tr>
                                    <Th>{t('Year')}</Th>
                                    <Th>{t('As')}</Th>
                                    <Th>{t('active')}</Th>
                                    <Th>{t('Ranting')}</Th>
                                    {usecase < 2 ? (<Th></Th>) : ''}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {rantingList.map(({ key, year, position, active, ranting }) => (
                                    <Tr key={key}>
                                        <Td>{year}</Td>
                                        <Td>{posisiList[position].label}</Td>
                                        <Td>{active ? (<Icon color={'green.400'} w={5} h={5} as={AiOutlineCheckCircle} />) : (<Icon color={'gray.400'} w={5} h={5} as={MdOutlineDoNotDisturbAlt} />)}</Td>
                                        <Td>{
                                            <Button size='xs' onClick={(e) => { openInfoRanting(ranting) }}>{ranting}</Button>
                                        }
                                        </Td>
                                        {usecase < 2 ? (
                                            <Td fontSize={'xs'}>
                                                <Button size='xs'  colorScheme='pink' onClick={() => confirmModalOpens(key)}><BsFillTrashFill/></Button>
                                            </Td>
                                        ) : ''}
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>


                    <Spacer minH='25px' />
                </VStack>
            </FormControl>

        </Center>

    )

}
