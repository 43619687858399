import React, { useState, useEffect, useContext } from "react"
import { GlobalContextProvider } from "../functions/GlobalContext"
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Text,
    Button,
    Center,
    VStack,
    Stack,
    Input,
    Checkbox,
    Spacer,
    Select,
    Grid,
    SimpleGrid,
} from '@chakra-ui/react'
import Const from "../../Consts"
import CountryList from "../../components/CountryList"
import StateList from "../../components/StateList"
import CityList from "../../components/CityList"
import DistrictList from "../../components/DistrictList"
import RantingList from "../../components/RantingList"
import { useTranslation } from 'react-i18next';
import { sanitizeTextarea, sanitizeEmail, sanitizeName, sanitizeNumber } from "../functions/Common"
import { useNavigate } from 'react-router-dom';
import { Twirp, TwirpProps } from "../functions/Twirp"
import { RantingExp } from "../RantingUpdate";



type RantingInfoModalProps = {
    isOpen: boolean
    rantingId: number
    closeFunction: () => void;
};
export default function RantingInfoModal(props: RantingInfoModalProps) {
    const { t, i18n } = useTranslation();
    const globalContext = useContext(GlobalContextProvider)
    const navigate = useNavigate()


    const defaultFormData: RantingExp = {
        key: 0,
        year: "2000",
        position: 0,
        ranting: 0,
        active: false,
    }


    const [rantingTree, setRantingTree] = useState({
        country_id: 0,
        state_id: 0,
        city_id: 0,
        cabang_id: 0,
        ranting_id: 0,
        country_name: "",
        state_name: "",
        city_name: "",
        cabang_name: "",
        ranting_name: "",
    })


    const loadRantingTree = async (idToSearch: number) => {

        const tprops: TwirpProps = {
            url: Const.API_RantingTree, headers: {}, timeout: 5000,
            data: { id: idToSearch }
        }
        const result = await Twirp(tprops)
        if (result.state == 2) {//other error
            //silent
        } else if (result.state == 1) {//twirp controlled error
            //silent
        } else { //successful
            setRantingTree({
                ...rantingTree, country_id: result.data.country_id, country_name: result.data.country_name,
                state_id: result.data.state_id, state_name: result.data.state_name,
                city_id: result.data.city_id, city_name: result.data.city_name,
                cabang_id: result.data.cabang_id, cabang_name: result.data.cabang_name,
                ranting_id: result.data.ranting_id, ranting_name: result.data.ranting_name,
            })
        }
    }




    useEffect(() => {
        loadRantingTree(props.rantingId)
    }, [props.isOpen])


    const saveAndClose = () => {
        props.closeFunction()
    }

    return (
        <>
            <Modal closeOnOverlayClick={false} size='md' isCentered isOpen={props.isOpen} onClose={props.closeFunction}>
                <ModalContent>
                    <ModalHeader>{t('info ranting')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>

                        <SimpleGrid columns={1} rounded={'lg'} borderWidth={'1px'} p='10px'>
                            <Grid templateColumns='repeat(2, 1fr)' gap={0} alignItems='center'>
                                <Text fontSize='sm' >{t('Country')}:</Text>
                                <Text fontSize='sm' >{rantingTree.country_name}</Text>
                            </Grid>
                            <Grid templateColumns='repeat(2, 1fr)' gap={0} alignItems='center'>
                                <Text fontSize='sm' >{t('State')}:</Text>
                                <Text fontSize='sm' >{rantingTree.state_name}</Text>
                            </Grid>
                            <Grid templateColumns='repeat(2, 1fr)' gap={0} alignItems='center'>
                                <Text fontSize='sm' >{t('City')}:</Text>
                                <Text fontSize='sm' >{rantingTree.city_name}</Text>
                            </Grid>
                            <Grid templateColumns='repeat(2, 1fr)' gap={0} alignItems='center'>
                                <Text fontSize='sm' >{t('District')}:</Text>
                                <Text fontSize='sm' >{rantingTree.cabang_name}</Text>
                            </Grid>
                            <Grid templateColumns='repeat(2, 1fr)' gap={0} alignItems='center'>
                                <Text fontSize='sm' >{t('Ranting')}:</Text>
                                <Text fontSize='sm' >{rantingTree.ranting_name}</Text>
                            </Grid>
                        </SimpleGrid>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}