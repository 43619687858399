import { useEffect } from "react"
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

type RouteParams = {
    target: string
}
type PageForwarderProps = {
    prefix: string
}
export default function PageForwarder(props: PageForwarderProps) {
    const navigate = useNavigate()
    const params = useParams<RouteParams>();

    useEffect(() => {
        navigate(props.prefix + params.target)
    }, [])


    return (<></>)
}
