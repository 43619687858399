import React, { useContext } from "react"
import { GlobalContextProvider } from "./components/functions/GlobalContext"
import {
  Box,
  Flex,
  Text,
  IconButton,
  Stack,
  Icon,
  Image,
  Select,
  Center,
  Menu, MenuButton, MenuList, MenuItem,
} from '@chakra-ui/react'
import {
  HamburgerIcon,
  CheckIcon, AddIcon, ExternalLinkIcon, RepeatIcon, EditIcon
} from '@chakra-ui/icons'
import { Link, NavLink } from 'react-router-dom'
import { RiOrganizationChart } from 'react-icons/ri';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { LuLogIn } from 'react-icons/lu';
import { BsPencilSquare } from 'react-icons/bs';
import { MdPeople, MdLogout } from 'react-icons/md';
import { IconType } from 'react-icons';
import { useTranslation } from 'react-i18next';
import { RiMessage2Line } from 'react-icons/ri'

export default function Navigation() {
  const globalContext = useContext(GlobalContextProvider)
  const { t, i18n } = useTranslation();

  const languageList = [{ value: "id", label: "Indonesia" }, { value: "en", label: "English" }]
  const onLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value
    i18n.changeLanguage(value)
  }

  return (
    <Box>
      <Flex
        bg={'#03a9f4'}
        color={'gray.600'}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={2}
        borderStyle={'none'}
        align={'center'}>

        <Flex flex={{ base: 0 }} justify={{ base: 'center', md: 'start' }}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>

            <Menu >
              <MenuButton mt='5px'
                as={IconButton}
                aria-label='Options'
                icon={<HamburgerIcon color='white' />}
                variant='outline'
              />
              <MenuList boxSize={"sm"} fontSize={'lg'} maxW={'100px'} maxH={'250px'}>
                {globalContext?.IsLoggedIn() ? '' : (
                  <MenuItem icon={<BsPencilSquare />} as={NavLink} to="/register">
                    {t("Register")}
                  </MenuItem>
                )}

                {globalContext?.IsLoggedIn() ? '' : (
                  <MenuItem icon={<LuLogIn />} as={NavLink} to="/login">
                    {t("Login")}
                  </MenuItem>
                )}

                {!globalContext?.IsLoggedIn() ? '' : (
                  <MenuItem icon={<RiOrganizationChart />} as={NavLink} to={"/forward/profile/" + (globalContext?.GetLoginData().userId)}>
                    {t("Profile")}
                  </MenuItem>
                )}

                {!globalContext?.IsLoggedIn() ? '' : (
                  <MenuItem icon={<RiOrganizationChart />} as={NavLink} to="/organization">
                    {t("Organization")}
                  </MenuItem>
                )}

                {!globalContext?.IsLoggedIn() ? '' : (
                  <MenuItem icon={<HiOutlineDocumentReport />} as={NavLink} to="/reports">
                    {t("Reports")}
                  </MenuItem>
                )}

                {!globalContext?.IsLoggedIn() ? '' : (
                  <MenuItem icon={<MdPeople />} as={NavLink} to="/administration">
                    {t("Administration")}
                  </MenuItem>
                )}

                <MenuItem icon={<RiMessage2Line />} as={NavLink} to="/contactus">
                  {t("Contact Us")}
                </MenuItem>

                {!globalContext?.IsLoggedIn() ? '' : (
                  <MenuItem icon={<MdLogout />} as={NavLink} to="/logout">
                    {t("Logout")}
                  </MenuItem>
                )}

              </MenuList>
            </Menu>

            <Select color="white" minW="95px" size="xs" onChange={onLanguageChange} defaultValue={0}>
              {languageList.map(({ value, label }) => (
                <option key={value} value={value} >
                  {label}
                </option>
              ))}
            </Select>
          </Stack>
        </Flex>

        <Flex flex={{ base: 1 }} justify={'flex-end'}>
          <Stack direction={'row'} spacing={4} alignItems={'center'}>
            <Text mt={'10px'} fontSize='md' color={'white'}>KPSN Central</Text>
            <Image referrerPolicy="no-referrer" src='/logo_kpsn.png' width='50' height='50' />
          </Stack>
        </Flex>

      </Flex>
    </Box>
  )




}


