import { useState, useEffect, useContext } from "react"
import { GlobalContextProvider } from "../components/functions/GlobalContext"
import {
    Box,
    Text,
    VStack,
    Center,
    Button,
    Grid, SimpleGrid, HStack,
    Flex,
    Spacer,
} from "@chakra-ui/react"
import InfoModal from "../components/modals/InfoModal"
import Const from "../Consts"
import Pesilat, { PesilatFields } from "../components/Pesilat"
import ImageUpload from "../components/ImageUpload"
import ImageUpload2 from "../components/ImageUpload2"
import CaptchaBox from "../components/Captcha"
import moment from "moment"
import axios from 'axios'
import { AxiosError } from "axios"
import ProgressModal from "../components/modals/progressModal"
import RegisterPrestasiPage, { PrestasiType } from "../components/RegisterPrestasi"
import RegisterCoachExpPage, { CoachExpType } from "../components/RegisterPrestasiCoach"
import RantingUpdatePage, { RantingExp, WrapperRTP } from "../components/RantingUpdate"
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Twirp, TwirpProps } from "../components/functions/Twirp"


type RouteParams = {
    userid: string
}
export default function ProfilePage() {
    const { t, i18n } = useTranslation();
    const globalContext = useContext(GlobalContextProvider)
    const navigate = useNavigate()
    const params = useParams<RouteParams>();

    const [usecase, setUsecase] = useState(0) //two ways: 1) as edit   2) as view from other user

    const [countryName, setCountryName] = useState("")
    const [pesilatForm, setPesilatForm] = useState<PesilatFields>({
        pesilatId: 0,
        countryId: 0,
        fullname: "",
        email: "",
        username: "",
        password: "",
        password2: "",
        birthdate: moment("01-01-1990", "DD-MM-YYYY").toDate(),
        pendidikan: 0,
        sabuk: 0,
        joindate: "1990",
        sex: "m",
        phone: "",
        isAthlete: false,
        isCoach: false,
        isReferee: false,
        isOther: false,
        otherProfession: "",
        coachLevel: 0,
        refereeLevel: 0,
        refFirstUpgrade: "1990",
        refLastUpgrade: "1990",
        file1Hash: "",
        file2Hash: "",
    })

    const [fileUploaded1, setFileUploaded1] = useState<File | null>(null)
    const [prestasiList, setPrestasiList] = useState<PrestasiType[]>([])
    const [coachExpList, setCoachExpList] = useState<CoachExpType[]>([])
    const [rantingExpList, setRantingExpList] = useState<RantingExp[]>([])
    const [fileUploaded2, setFileUploaded2] = useState<File | null>(null)
    const [captchaID, setCaptchaID] = useState<string>("")
    const [captchaAnswer, setCaptchaAnswer] = useState<string>("")
    const [enableSubmit, setEnableSubmit] = useState<boolean>(false)
    const [toRefresh, setToRefresh] = useState<number>(0)
    const [file1toPushChildComponent, setFile1toPushChildComponent] = useState("")
    const [file2toPushChildComponent, setFile2toPushChildComponent] = useState("")


    const [modalData, setModalData] = useState({
        title: "",
        main: "",
        isOpen: false,
    })
    const closeModal = () => {
        setModalData({ ...modalData, isOpen: false })
    }

    const [progressModal, setProgressModal] = useState({
        title: "Processing Profile Update",
        progress: 0,
        isOpen: false,
    })
    const closeProgressModal = () => {
        setProgressModal({ ...progressModal, isOpen: false })
    }



    const loadLocationInfo = async (idToSearch: number) => {

        const tprops: TwirpProps = {
            url: Const.API_ReadCountry, headers: {}, timeout: 5000,
            data: { id: idToSearch }
        }
        const result = await Twirp(tprops)
        if (result.state == 2) {//other error
            //silent
        } else if (result.state == 1) {//twirp controlled error
            //silent
        } else { //successful
            setCountryName(result.data.nama)
        }
    }

    const pesilatIsFilled = async (enteredData: PesilatFields) => {
        loadLocationInfo(enteredData.countryId)
        setPesilatForm(enteredData)
        setFile1toPushChildComponent(enteredData.file1Hash)
        setFile2toPushChildComponent(enteredData.file2Hash)
    }
    const file1IsUploaded = (id: File | null) => {
        setFileUploaded1(id)
    }
    const file2IsUploaded = (id: File | null) => {
        setFileUploaded2(id)
    }
    const captchaAnswered = (id: string, answer: string) => {
        setCaptchaID(id)
        setCaptchaAnswer(answer)
        if (answer.length == 6) {
            setEnableSubmit(true)
        } else {
            setEnableSubmit(false)
        }
    }
    const prestasiIsAdded = (prestasi: PrestasiType[]) => {
        setPrestasiList(prestasi)
    }
    const coachExpIsAdded = (list: CoachExpType[]) => {
        setCoachExpList(list)
    }
    const rantingExpIsAdded = (list: RantingExp[]) => {
        setRantingExpList(list)
    }




    const updateAccount = async () => {
        try {
            let formData = new FormData();
            formData.append("country_id", "" + pesilatForm.countryId)
            formData.append("fullname", pesilatForm.fullname)
            formData.append("username", pesilatForm.username)
            formData.append("email", pesilatForm.email)
            if (pesilatForm.password.length >= 8) {
                formData.append("password", pesilatForm.password)
            }
            formData.append("pendidikan", "" + pesilatForm.pendidikan)
            formData.append("sabuk", "" + pesilatForm.sabuk)
            formData.append("joindate", "" + pesilatForm.joindate)
            formData.append("sex", pesilatForm.sex)
            formData.append("phone", pesilatForm.phone)
            formData.append("captcha", captchaID)
            formData.append("answer", captchaAnswer)
            formData.append("file1name", fileUploaded1?.name || "")

            //athlete
            if (pesilatForm.isAthlete) {
                formData.append("is_athlete", "1")
                let serialized = ""
                prestasiList.forEach((value, index) => {
                    serialized += JSON.stringify(value) //Do not add separator in last element
                    if (index < prestasiList.length - 1) {
                        serialized += "|"
                    }
                });
                formData.append("list_prestasi", serialized)
            }

            //coach
            if (pesilatForm.isCoach) {
                formData.append("is_coach", "1")
                formData.append("coach_level", "" + pesilatForm.coachLevel)
                let serialized = ""
                coachExpList.forEach((value, index) => {
                    serialized += JSON.stringify(value) //Do not add separator in last element
                    if (index < coachExpList.length - 1) {
                        serialized += "|"
                    }
                });
                formData.append("coach_exp", serialized)
            }

            if (pesilatForm.isOther) {
                formData.append("other_profession", pesilatForm.otherProfession)
            }

            //referee
            if (pesilatForm.isReferee) {
                formData.append("is_referee", "1")
                formData.append("referee_level", "" + pesilatForm.refereeLevel)
                formData.append("referee_first_upgrade", pesilatForm.refFirstUpgrade)
                formData.append("referee_last_upgrade", pesilatForm.refLastUpgrade)
            }

            //list rantings:
            if (rantingExpList.length > 0) {
                let wrapper: WrapperRTP = { list: [] }
                rantingExpList.forEach((value, _) => {
                    wrapper.list.push({
                        pesilat_id: +0,  //this field will be ignored because using /update/id in URL param
                        ranting_id: +value.ranting,
                        active: value.active,
                        position: +value.position,
                        year: +value.year,
                    })
                });
                const serialized = JSON.stringify(wrapper)
                formData.append("list_rantings", serialized)
            }




            //for debugging purpose, file upload is put on last
            const fu1 = fileUploaded1?.name || ""
            if (fu1 !== "") {
                formData.append("file1", fileUploaded1 || "")
            }
            const fu2 = fileUploaded2?.name || ""
            if (pesilatForm.isReferee && fu2 !== "") {
                formData.append("file2name", fileUploaded2?.name || "")
                formData.append("file2", fileUploaded2 || "")
            }


            //payload is ready========================================================================
            const res = await axios.post(Const.API_UpdateProfile + pesilatForm.pesilatId, formData,
                {
                    timeout: 15000,
                    headers: { Authorization: globalContext?.GetLoginData().token },
                }
            )

            if (res.hasOwnProperty("data") && res.data) {
                setModalData({
                    ...modalData, isOpen: true, title: t("Successful"),
                    main: t("update_successful")
                })
                window.scrollTo(0, 0)
            } else {
                console.log(res)
                throw new Error(t("unknown error"))
            }

        } catch (err) {
            let errorToPrint = (err as Error).message
            if (axios.isAxiosError(err)) {
                const error = err as AxiosError
                if (error.response?.hasOwnProperty("data")) {
                    errorToPrint = error.response.data as string
                }

                if (error.response?.status == 401) {
                    closeProgressModal()
                    navigate('/logout')
                }
            }
            setModalData({ ...modalData, isOpen: true, title: t("Failed"), main: errorToPrint })
        }

        setToRefresh(toRefresh + 1)
        setCaptchaAnswer("")
        setEnableSubmit(true)
        closeProgressModal()
    }


    const validateInputs = async () => {

        if (pesilatForm.email.length <= 7) {
            setModalData({ ...modalData, isOpen: true, title: t("Please check your data"), main: t("missing_email") })
            return
        }

        if (pesilatForm.password.length > 0) {
            if (pesilatForm.password.length < 8) {
                setModalData({ ...modalData, isOpen: true, title: t("Please check your data"), main: t("missing_password") })
                return
            }
            if (pesilatForm.password != pesilatForm.password2) {
                setModalData({ ...modalData, isOpen: true, title: t("Please check your data"), main: t("password_repeat") })
                return
            }
        }
        if (pesilatForm.sabuk < 1) {
            setModalData({ ...modalData, isOpen: true, title: t("Please check your data"), main: t("missing_belt") })
            return
        }
        if (pesilatForm.pendidikan < 1) {
            setModalData({ ...modalData, isOpen: true, title: t("Please check your data"), main: t("missing_education") })
            return
        }
        if (pesilatForm.phone.length < 10) {
            setModalData({ ...modalData, isOpen: true, title: t("Please check your data"), main: t("missing_phone") })
            return
        }
        if (+pesilatForm.joindate < 1968) {
            setModalData({ ...modalData, isOpen: true, title: t("Please check your data"), main: t("missing_joindate") })
            return
        }
        if (pesilatForm.isCoach && pesilatForm.coachLevel < 1){
            setModalData({ ...modalData, isOpen: true, title: t("Please check your data"), main: t("missing_coachlevel") })
            return
        }
        if (pesilatForm.isReferee) {
            if (+pesilatForm.refFirstUpgrade < 1968) {
                setModalData({ ...modalData, isOpen: true, title: t("Please check your data"), main: t("missing_ref_firstup") })
                return
            }
            if (+pesilatForm.refLastUpgrade < 1968) {
                setModalData({ ...modalData, isOpen: true, title: t("Please check your data"), main: t("missing_ref_lastup") })
                return
            }
        }

        if (captchaAnswer.length < 6) {
            setModalData({ ...modalData, isOpen: true, title: t("Please check your data"), main: t("missing_captcha") })
            return
        }

        setEnableSubmit(false)
        setProgressModal({ ...progressModal, isOpen: true })
        updateAccount()
    }


    useEffect(() => {

        let username = globalContext?.GetLoginData().username || ""
        if (username === "") {
            navigate('/login')
        }else {

            const userId = globalContext?.GetLoginData().userId || 0
            const pesilatID = +(params.userid || userId)
            if (pesilatID > 0) {
                if (globalContext?.GetLoginData().isAdmin) {
                    setUsecase(1) //admin is allowed to edit
                } else if (pesilatID == globalContext?.GetLoginData().userId) {
                    setUsecase(1) //editing own profile
                } else {
                    setUsecase(2) //viewing other user profile
                }
            }
        }
    }, [])




    return (
        <Box py={"10px"} px={"10px"} maxW='m' borderWidth='0px' borderRadius='lg' overflow='hidden'>

            <InfoModal isOpen={modalData.isOpen} title={modalData.title} mainText={modalData.main} closeFunction={closeModal} />
            <ProgressModal isOpen={progressModal.isOpen} title={progressModal.title} progress={progressModal.progress} closeFunction={closeProgressModal} />

            <SimpleGrid py={"10px"} px={"10px"} borderWidth='2px' borderRadius='lg' overflow='auto' >
                <SimpleGrid columns={[1]} maxW={['340px', '700px']} minW={['340px', '700px']} justifySelf={'center'}>
                    <Center>
                        <Text fontSize='sm'>{countryName}</Text>
                    </Center>
                </SimpleGrid>
            </SimpleGrid>

            <Pesilat selectedFunction={pesilatIsFilled} refreshCounter={toRefresh} />

            {pesilatForm.isAthlete ? (<RegisterPrestasiPage selectedFunction={prestasiIsAdded} />) : ''}

            {pesilatForm.isCoach ? (<RegisterCoachExpPage selectedFunction={coachExpIsAdded} />) : ''}

            {pesilatForm.isReferee ? (<ImageUpload2 selectedFunction={file2IsUploaded} refreshCounter={toRefresh} existingFile={file2toPushChildComponent} />) : ''}

            <RantingUpdatePage selectedFunction={rantingExpIsAdded} />

            <ImageUpload selectedFunction={file1IsUploaded} refreshCounter={toRefresh} existingFile={file1toPushChildComponent} />

            <CaptchaBox selectedFunction={captchaAnswered} refreshIndicator={toRefresh} />

            {usecase < 2 ? (
                <Center py={"10px"} px={"10px"} maxW='m' borderWidth='0px' borderRadius='lg' overflow='hidden' >
                    <VStack minW="300px">
                        <Button colorScheme='blue' isDisabled={!enableSubmit} onClick={validateInputs}>{t('Update Profile')}</Button>
                    </VStack>
                </Center>
            ) : ''}

        </Box>

    )

}
