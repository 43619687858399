import React, { useState, useEffect, useContext } from "react"
import { GlobalContextProvider } from "../components/functions/GlobalContext"
import {
    Box,
    Text,
    VStack,
    Stack,
    Center,
    Button,
    Input,
    Textarea,
    Spacer,
} from "@chakra-ui/react"
import InfoModal from "../components/modals/InfoModal"
import Const from "../Consts"
import CaptchaBox from "../components/Captcha"
import ProgressModal from "../components/modals/progressModal"
import { useTranslation } from 'react-i18next';
import { sanitizeTextarea, sanitizeEmail, sanitizeName, sanitizeNumber } from "../components/functions/Common"
import { useNavigate } from 'react-router-dom';
import { Twirp, TwirpProps } from "../components/functions/Twirp"


export default function ContactUsPage() {
    const { t, i18n } = useTranslation();
    const globalContext = useContext(GlobalContextProvider)
    const navigate = useNavigate()

    const [captchaID, setCaptchaID] = useState<string>("")
    const [captchaAnswer, setCaptchaAnswer] = useState<string>("")
    const [enableSubmit, setEnableSubmit] = useState<boolean>(false)
    const [messageSubmitted, setMessageSubmitted] = useState<boolean>(false)
    const [toRefresh, setToRefresh] = useState<number>(0)

    const [modalData, setModalData] = useState({
        title: "",
        main: "",
        isOpen: false,
    })
    const closeModal = () => {
        setModalData({ ...modalData, isOpen: false })
    }

    const [progressModal, setProgressModal] = useState({
        title: t('sending_message'),
        progress: 0,
        isOpen: false,
    })
    const closeProgressModal = () => {
        setProgressModal({ ...progressModal, isOpen: false, progress: 0 })
    }

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        text: "",
    })

    const captchaAnswered = (id: string, answer: string) => {
        setCaptchaID(id)
        setCaptchaAnswer(answer)

        if (answer.length == 6) {
            setEnableSubmit(true)
        } else {
            setEnableSubmit(false)
        }
    }

    const sendMessageAPI = async () => {

        const props: TwirpProps = {
            url: Const.API_ContactUs, headers: {}, timeout: 5000,
            data: {
                captcha_id: captchaID, captcha_answer: captchaAnswer,
                name: formData.name, email: formData.email, phone: formData.phone, msg: formData.text
            }
        }
        const result = await Twirp(props)
        if (result.state == 2) {//other error
            setModalData({ ...modalData, isOpen: true, title: result.axiosError.code, main: result.axiosError.message })
        } else if (result.state == 1) {//twirp controlled error
            let msg = result.axiosError.response?.data?.msg || result.axiosError.message
            let msgTitle = result.axiosError.response?.data?.code || result.axiosError.code

            if (result.axiosError.response?.status == 401) {
                msgTitle = t('Failed')
                msg = t('wrong_captcha')
            }
            setModalData({ ...modalData, isOpen: true, title: msgTitle, main: msg })
        } else { //successful
            setMessageSubmitted(true)
        }


        closeProgressModal()
        setToRefresh(toRefresh + 1)
        setCaptchaAnswer("")
    }


    const submitMessage = async () => {

        if (formData.name.length < 3) {
            setModalData({ ...modalData, isOpen: true, title: t("Please check your data"), main: t("missing_fullname") })
            return
        }
        if (formData.email.length <= 7) {
            setModalData({ ...modalData, isOpen: true, title: t("Please check your data"), main: t("missing_email") })
            return
        }
        if (formData.phone.length < 10) {
            setModalData({ ...modalData, isOpen: true, title: t("Please check your data"), main: t("missing_phone") })
            return
        }
        if (formData.text.length < 30) {
            setModalData({ ...modalData, isOpen: true, title: t("Please check your data"), main: t("missing_textarea") })
            return
        }

        setEnableSubmit(false)
        setProgressModal({ ...progressModal, isOpen: true })
        sendMessageAPI()
    }


    useEffect(() => {
    }, [])



    return (
        <Box py={"10px"} px={"10px"} maxW='m' borderWidth='0px' borderRadius='lg' overflow='hidden'>

            <InfoModal isOpen={modalData.isOpen} title={modalData.title} mainText={modalData.main} closeFunction={closeModal} />
            <ProgressModal isOpen={progressModal.isOpen} title={progressModal.title} progress={progressModal.progress} closeFunction={closeProgressModal} />

            <Center py={"10px"} px={"10px"} maxW='m' borderWidth='2px' borderRadius='lg' overflow='hidden' >

                {!messageSubmitted ? (
                    <VStack spacing='0px'>
                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                            <Text minW="125px">{t('Your Name')}</Text>
                            <Input type='text' minW='248px' maxW='248px' value={formData.name} onChange={(e) => setFormData({ ...formData, name: sanitizeName(e.target.value) })} maxLength={64} />
                        </Stack>
                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                            <Text minW="125px">{t('Your E-mail')}</Text>
                            <Input type='email' minW='248px' maxW='248px' value={formData.email} onChange={(e) => setFormData({ ...formData, email: sanitizeEmail(e.target.value) })} maxLength={64} />
                        </Stack>
                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                            <Text minW="125px">{t('Mobile Phone')}</Text>
                            <Input type='text' minW='248px' maxW='248px' value={formData.phone} onChange={(e) => setFormData({ ...formData, phone: sanitizeNumber(e.target.value) })} maxLength={16} />
                        </Stack>
                        <Textarea
                            value={formData.text}
                            onChange={(e) => setFormData({ ...formData, text: sanitizeTextarea(e.target.value) })}
                            placeholder={t('Write Message')}
                            size='sm'
                            resize='vertical' maxLength={512} />

                        <Box minH="20px"></Box>
                        <CaptchaBox selectedFunction={captchaAnswered} refreshIndicator={toRefresh} />

                        <Center py={"10px"} px={"10px"} maxW='m' borderWidth='0px' borderRadius='lg' overflow='hidden' >
                            <VStack minW="300px">
                                <Button colorScheme='blue' isDisabled={!enableSubmit} onClick={submitMessage}>{t('Contact Us')}</Button>
                            </VStack>
                        </Center>
                    </VStack>
                ) : (

                    // after submission---------------------------
                    <VStack spacing='0px'>
                            <Text minW="125px"><b>{t('thanks_contact')}</b></Text>
                            <Text minW="125px">{t('contact_soon')}</Text>
                    </VStack>
                )}
            </Center>



        </Box>

    )

}
