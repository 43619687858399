import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEnglish from "./locales/en/translation.json"
import translationIndonesia from "./locales/id/translation.json"


const resources = {
    en: {
        translation: translationEnglish,
    },
    id: {
        translation: translationIndonesia,
    },
}

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "id", 
        // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: true // react already safes from xss
        }
    });

export default i18n;