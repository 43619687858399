import * as React from "react"
import {
  ChakraProvider,
  Box,
  theme,
} from "@chakra-ui/react"
import Navigation from "./Navigation"
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import AdministrationPage from "./pages/Administration";
import OrganizationPage from "./pages/Organization";
import LoginPage from "./pages/Login";
import LogoutPage from "./pages/Logout";
import RegisterPage from "./pages/Register";
import PostLoginPage from "./pages/PostLogin";
import ReportsPage from "./pages/Reports";
import ProfilePage from "./pages/Profile";
import ContactUsPage from "./pages/ContactUs"
import PageForwarder from "./pages/Forwarder";

export const App = () => (
  <Router>
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl">
        <Navigation />
      </Box>
      <div className="container">
        <Routes>
          <Route path="/" element={<PostLoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/administration" element={<AdministrationPage />} />
          <Route path="/organization" element={<OrganizationPage />} />
          <Route path="/reports" element={<ReportsPage />} />
          <Route path="/postlogin" element={<PostLoginPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/profile/:userid" element={<ProfilePage />} />
          <Route path="/contactus" element={<ContactUsPage />} />
          <Route path="/forward/profile/:target" element={<PageForwarder prefix="/profile/"/>} />
        </Routes>
      </div>
    </ChakraProvider>
  </Router>

)
