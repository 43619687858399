import React, { useState, useEffect, useContext } from "react"
import {
  Box,
  Text,
  VStack,
  Stack,
  Center,
  FormControl,
  Input,
  Button,
} from "@chakra-ui/react"
import { Tabs, TabList, TabPanels, Tab, TabPanel, TabIndicator } from '@chakra-ui/react'
import InfoModal from "../components/modals/InfoModal"
import Const from "../Consts"
import { Twirp, TwirpProps } from "../components/functions/Twirp"
import { GlobalContextProvider } from "../components/functions/GlobalContext"
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EmptyPage } from "./Empty"

export default function OrganizationPage() {
  const globalContext = useContext(GlobalContextProvider)
  const { t, i18n } = useTranslation();
  const navigate = useNavigate()

  //solution #1 ?
  //https://stackoverflow.com/questions/71369320/how-to-controling-browser-back-button-with-react-router-dom-v6
  // window.onpopstate = () => {
  //   console.log("onpopstate")
  // }

  useEffect(() => {
    let username = globalContext?.GetLoginData().username || ""
    if (username === "") {
        navigate('/login')
    }

    //solution 1 cont.
    // return () => {
    //   window.onpopstate = () => { }
    // }

    //solution #2 disable back button
    // window.history.pushState(null, document.title, window.location.href);
    // window.addEventListener('popstate', function (event) {
    //   window.history.pushState(null, document.title, window.location.href);
    // });
  }, [])

  return (
    <Box py={"10px"} px={"10px"} maxW='m' borderWidth='0px' borderRadius='lg' overflow='hidden'>

      <Tabs variant={"enclosed-colored"}>
        <TabList>
          <Tab>{t('Browse')}</Tab>
          <Tab>{t('Find Pesilat')}</Tab>
        </TabList>
        <TabIndicator
          mt="-1.5px"
          height="2px"
          bg="blue.500"
          borderRadius="1px"
        />
        <TabPanels>
          <TabPanel>
            <p>Coming Soon!</p>
          </TabPanel>
          <TabPanel>
            <p>Coming Soon!</p>
          </TabPanel>
        </TabPanels>
      </Tabs>

    </Box>
  )
}
