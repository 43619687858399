import { useState, useEffect, useContext } from "react"
import { GlobalContextProvider } from "../components/functions/GlobalContext"
import {
    Box,
    Text,
    VStack,
    Stack,
    Center,
    FormControl,
    Input,
    Button,
} from "@chakra-ui/react"
import InfoModal from "../components/modals/InfoModal"
import Const from "../Consts"
import { Twirp, TwirpProps } from "../components/functions/Twirp"
import { NavLink } from "react-router-dom"
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ProgressModal from "../components/modals/progressModal"

export default function LoginPage() {
    const globalContext = useContext(GlobalContextProvider)
    const navigate = useNavigate()
    const { t, i18n } = useTranslation();


    const [formData, setFormData] = useState({
        username: "",
        password: "",
        submitDisabled: false,
    })

    const [modalData, setModalData] = useState({
        title: "",
        main: "",
        isOpen: false,
    })
    const closeModal = () => {
        setModalData({ ...modalData, isOpen: false })
    }

    const [progressModal, setProgressModal] = useState({
        title: "Processing Login...",
        progress: 0,
        isOpen: false,
    })
    const closeProgressModal = () => {
        setProgressModal({ ...progressModal, isOpen: false })
    }



    const onSubmitLogin = async () => {
        setFormData({ ...formData, submitDisabled: true })
        setProgressModal({ ...progressModal, isOpen: true })

        const props: TwirpProps = {
            url: Const.API_Login_URL, headers: {}, timeout: 5000,
            data: { username: formData.username, password: formData.password }
        }
        const result = await Twirp(props)
        if (result.state == 2) {//other error
            setModalData({ ...modalData, isOpen: true, title: result.axiosError.code, main: result.axiosError.message })
        } else if (result.state == 1) {//twirp controlled error
            let msg = result.axiosError.response?.data?.msg || result.axiosError.message
            let msgTitle = result.axiosError.response?.data?.code || result.axiosError.code
            if(msg.includes('wrong')){
                msgTitle = t('err_login')
                msg = t('wrong_login')
            }
            setModalData({ ...modalData, isOpen: true, title: msgTitle, main: msg })
        } else { //successful
            closeProgressModal()
            //store login information to share globally
            globalContext?.LogIn(formData.username, result.data.token, result.data.user_id, result.data.level, result.data.is_admin)

            //navigate to post login page
            navigate('/postlogin')
        }

        closeProgressModal()
        setFormData({ ...formData, submitDisabled: false })
    }

    useEffect(() => {

    }, [])



    return (
        <Box py={"10px"} px={"10px"} maxW='m' borderWidth='0px' borderRadius='lg' overflow='hidden'>

            <InfoModal isOpen={modalData.isOpen} title={modalData.title} mainText={modalData.main} closeFunction={closeModal} />
            <ProgressModal isOpen={progressModal.isOpen} title={progressModal.title} progress={progressModal.progress} closeFunction={closeProgressModal} />

            <Center py={"10px"} px={"10px"} maxW='m' borderWidth='2px' borderRadius='lg' overflow='hidden' >

                <FormControl alignItems={'center'}>

                    <VStack>
                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                            <Text minW="100px">{t('Username')}</Text>
                            <Input size={"sm"} type='username' value={formData.username} onChange={(e) => setFormData({ ...formData, username: e.target.value })} maxLength={32} />
                        </Stack>
                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                            <Text minW="100px">{t('Password')}</Text>
                            <Input size={"sm"} type='password' value={formData.password} onChange={(e) => setFormData({ ...formData, password: e.target.value })} maxLength={32} />
                        </Stack>
                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                            <Button colorScheme='blue' isDisabled={formData.submitDisabled} onClick={onSubmitLogin}>{t('Login')}</Button>
                            <Button colorScheme='red' isDisabled={formData.submitDisabled} >{t('Forgot Password')}</Button>
                        </Stack>
                    </VStack>
                </FormControl>

            </Center>
            <Center py={"10px"} px={"10px"} maxW='m' borderWidth='0px' borderRadius='lg' overflow='hidden'>
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <Text fontSize='sm'>{t('no_account')} </Text>
                    <Button as={NavLink} size='xs' colorScheme='green' to='/register'>{t('Register Here')}</Button>
                    </Stack>
            </Center>
        </Box>

    )

}
