import React, { useState, useEffect, useContext } from "react"
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Text,
    Button,
} from '@chakra-ui/react'

type InfoModalProps = {
    isOpen: boolean
    title: string
    mainText: string
    closeFunction: () => void;
};
export default function InfoModal(props: InfoModalProps) {
    return (
        <>
            <Modal closeOnOverlayClick={false} isCentered isOpen={props.isOpen} onClose={props.closeFunction}>
                <ModalOverlay
                    bg='blackAlpha.300'
                    backdropFilter='blur(10px) hue-rotate(90deg)'
                />
                <ModalContent>
                    <ModalHeader>{props.title}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>{props.mainText}</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={props.closeFunction}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}