import React, { useState, useEffect, useContext } from "react"
import { GlobalContextProvider } from "../components/functions/GlobalContext"
import {
    Box,
    VStack,
    Center,
    Select,
} from "@chakra-ui/react"
import InfoModal from "../components/modals/InfoModal"
import Const from "../Consts"
import { Twirp, TwirpProps } from "../components/functions/Twirp"
import { useTranslation } from 'react-i18next';

type StateListProps = {
    parentId: number,
    selectedFunction: (chosen: number) => void
};
export default function StateList(props: StateListProps) {
    const { t, i18n } = useTranslation();
    const globalContext = useContext(GlobalContextProvider)

    type SingleListItem = {
        value: number,
        label: string,
        disabled?: boolean
    }
    const defaultState = [{ disabled: true, value: 0, label: t("-select-") }]
    const [itemData, setItemData] = useState<SingleListItem[]>(defaultState)
    const [selectedValue, setSelectedValue] = useState(0)

    const [modalData, setModalData] = useState({
        title: "",
        main: "",
        isOpen: false,
    })
    const closeModal = () => {
        setModalData({ ...modalData, isOpen: false })
    }

    const onItemSelected = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value
        setSelectedValue(+value)
        props.selectedFunction(+value)//convert to number
    }

    const populateData = async () => {

        const tprops: TwirpProps = {
            url: Const.API_ListStates, headers: {}, timeout: 5000, data: { id: props.parentId }
        }
        const result = await Twirp(tprops)
        if (result.state == 2) {//other error
            // setModalData({ ...modalData, isOpen: true, title: "other error", main: result.msg })
        } else if (result.state == 1) {//twirp controlled error
            // setModalData({ ...modalData, isOpen: true, title: result.code, main: result.msg })
        } else { //successful
            closeModal()

            type CommonResult = {
                id: number,
                nama: string
            }
            const commons = result.data.Commons as CommonResult[]
            const resultCountries = commons.map(d => (
                {
                    label: d.nama,
                    value: d.id
                }
            ))
            setItemData([...defaultState, ...resultCountries])

        }
    }

    useEffect(() => {
        if (props.parentId > 0) {
            populateData()
            setSelectedValue(0)
        }
    }, [props.parentId])




    return (
        <Box py={"0px"} px={"0px"} maxW='m' borderWidth='0px'  overflow='hidden'>
            <InfoModal isOpen={modalData.isOpen} title={modalData.title} mainText={modalData.main} closeFunction={closeModal} />

            <Center py={"0px"} px={"0px"} maxW='m' borderWidth='0px'  overflow='hidden' >
                <VStack>
                    <Select minW={['158px','192px']} size='sm' onChange={onItemSelected} value={selectedValue}>
                        {itemData.map(({ value, label }) => (
                            <option key={value} value={value} disabled={value == 0}>
                                {label}
                            </option>
                        ))}
                    </Select>

                </VStack>
            </Center>
        </Box>

    )

}
